.support-email-row {
  align-items: center;
}

.support-email,
.support-email-button {
  color: #2e3074;
}
.support-email-button {
  font-size: 16px !important;
}

.support-email-input {
  height: 57px !important;
  font-size: 23px !important;
  font-weight: 300;
  opacity: 1;
}
