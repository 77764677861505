@import '../../../_assets/css/variables.scss';

@keyframes moveIn {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

li {
  list-style-type: none;
}

.which-is-more-import {
  height: 80px;
  width: 477px;
  color: #2e3074;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: -0.57px;
  line-height: 40px;
  text-align: center;
}

.this-helps-us-to-ref {
  height: 19px;
  color: #2e3074;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
}

.select-more-important-business-button-container {
  text-align: center;

  .col-6 {
    .card-steps:last-child {
      float: left;
      @media (max-width: 575.98px) {
        float: none;
      }
    }

    &:first-child {
      .card-steps {
        float: right;
        @media (max-width: 575.98px) {
          float: none;
        }
      }
    }
  }

  .select-more-important-business-button {
    padding: 30px 40px 30px 40px;
    border-radius: $border-radius;
    cursor: pointer;
    font-size: 20px;
    display: flex;
    max-width: 193px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
    flex-direction: column;
    align-items: center;
    border: 2px solid transparent;

    img {
      width: 117px;
      height: auto;
    }
  }

  .improve-the-natural {
    height: 69px;
    width: 141px;
    color: #464646;
    font-family: Chapeau-Regular;
    font-size: 17px;
    line-height: 23px;
    display: block;
    margin-top: 2em;
  }
}

.avoid-selected {
  background-color: #ffe6e6;
}

.support-selected,
.list-group-item.support-selected {
  background-color: #e6fff3;
  border: 2px solid #d7f2ea !important;
}

.unselected {
  background-color: #f2f2f2;
}

.start-item {
  float: left;
}

.start-container {
  max-width: 800px;
}

.background-color-white {
  background-color: white;

  &.copy-paste-portfolio {
    border-radius: 10px;

    .row {
      margin-bottom: 15px;

      .col-sm-6:last-child {
        color: #b6c3c6;
        font-size: 14px;
      }
    }
  }
}

.multi-step {
  width: 100%;
}

.client-steps,
.showup-modal,
.register-steps {
  @media (max-width: 768px) {
    padding: 0;
  }

  h2 {
    padding: 0 1em;
  }

  p,
  span {
    color: $header-font-color;
    margin-top: 1em;
    margin-bottom: 0;
  }

  .header {
    p,
    span {
      font-size: 32px;
    }
    .category-heading {
      font-family: $title-font-family;
      font-weight: bolder;
    }
  }

  .sub-heading {
    margin-top: 30px;
    @media (max-width: 768px) {
      margin-top: 50px;
    }
  }

  .list-group-item {
    border: 2px solid transparent;
    padding: 1.25rem;
    border-color: transparent;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);

    &.avoid-selected {
      background-color: #ffe5ea;
      border: 2px solid #ffe3e8;
      box-shadow: none;
    }
  }

  .time-frame {
    th {
      & input {
        appearance: none;
        width: 2em;
        height: 2em;
        border: 0.35em solid #eae9eb;
        border-radius: 50%;
      }

      & input:checked {
        background-color: #a4e6df;
      }
    }

    td {
      padding: 0;

      & label {
        font-size: 18px;
      }
    }
  }
}

.nav-tabs.pill-nav {
  border: none;
  justify-content: center;

  .nav-item {
    position: relative;
    width: 192px;
    text-align: center;
    @media (max-width: 768px) {
      max-width: 120px;
    }
    @media (max-width: 575.98px) {
      max-width: 114px;
    }

    a {
      padding: 1em;
      background-color: #f0f0f0;
      border-radius: 0;
      color: #656577 !important;
      border-right: 1px solid #e3dfdf;
      border-left: 1px solid #e3dfdf;
      @media (max-width: 768px) {
        padding: 1em;
        font-size: 0.9rem;
      }

      span {
        color: $header-font-color;
        font-size: 12px;
        line-height: 18px;
        @media (max-width: 768px) {
          display: none;
        }
      }

      &.active-tab {
        color: $header-font-color !important;
        font-size: 16px;
        background-color: #95e6dc;
        box-shadow: none;
        @media (max-width: 575.98px) {
          font-size: 14px;
        }

        span {
          @media (max-width: 768px) {
            position: absolute;
            top: -15px;
            border-radius: 50%;
            right: -10px;
            background-color: #bc5f80;
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            padding: 5px;
            font-size: 11px;
            z-index: 1;
          }
        }
      }
    }

    &:first-child {
      a {
        border-radius: 30px 0 0 30px;
        border-color: transparent;
      }
    }

    &:last-child {
      a {
        border-radius: 0 30px 30px 0;
        border-color: transparent;
      }
    }
  }
}

label.terms-and-condition {
  color: #313132 !important;

  .form-check-input {
    margin-left: -11px !important;
  }
}

.message {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    margin-right: 0.75rem;
  }

  strong {
    color: $header-font-color;
    font-size: 20px;
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-logo {
  padding-left: 1em;
  max-width: 410px;
  display: inline-block;
  color: #313132 !important;
}

.card-link-blue {
  color: #3e61df;
  font-size: 15px;
}

.list-item-layout {
  display: flex;

  .text-primary {
    color: #3e61df;
    font-size: 15px;
  }

  .list-item-img {
    display: flex;
    width: 80px;

    img {
      width: auto;
      height: 50px;
    }
  }

  .list-item-description {
    width: 124px;
    padding: 0 10px 0 10px !important;

    span {
      text-transform: lowercase;

      &:first-letter {
        text-transform: uppercase;
      }
    }
  }

  .list-item-info {
    position: relative;
    align-self: center;

    .list-item-description {
      color: #424242;
      font-size: 14px;
      padding-top: 5px;
    }

    button.hide-button {
      display: block;
      position: absolute;
      z-index: 2;
      width: 50px;
      height: 50px;
      top: 0;
      right: 0;
      opacity: 0;
    }

    img {
      display: block;
      position: relative;
      z-index: 1;
    }
  }
}

.container-body-modal {
  .cursor-hand {
    img {
      width: 18px;
      height: 18px;
    }
  }

  .content-body-modal {
    margin-top: 1em;
    display: flex;
    flex-direction: column;

    .icon-modal {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 90px;
      }
    }

    .title-content-modal {
      margin: 1em 0;
      display: block;
      text-align: center;
    }
  }
}

.filter-and-sort-button {
  button.btn {
    padding: 5px;
    font-size: 15px !important;
    color: #2e3074;
    max-width: 190px !important;
  }
}

.investing-profile {
  max-width: 800px !important;
  .welcome-header {
    font-weight: 300;
    font-size: 32px;
    color: #2e3074;
  }
}

.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.15);
  .showup-modal {
    margin-top: 135px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15) !important;
    background-color: white;
    box-sizing: border-box;
    padding: 20px 20px 0;
    animation: moveIn 0.5s;
  }
}
