@import '../../../src/scss/variables';

.common-filters-result {
  margin-top: 10px !important;
}

.types-btn {
  line-height: 5px;
  font-size: 15px;
  height: 34px;
  border-radius: 12px;
  border: 0.5px solid #8bebe0;
}

.selected {
  background-color: #8bebe0;
  border-color: #8bebe0 !important;
  box-shadow: none;
  margin-bottom: 5px;
}
.unselected {
  color: $header_font-color;
  background-color: white;
  border-color: $button-hover;
  box-shadow: none;
  margin-bottom: 5px;
  &:hover {
    border-color: $button-hover !important;
    background-color: #8bebe0;
  }
}

.modal-common-filters {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, auto);
  place-items: center;
}
