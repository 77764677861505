// Colours -----------------------------------------
$step_color_grey: #777;
$step_color_grey2: #dfe3e4;
$step_color_blue: #2183dd;
$step_color_green: #009900;
$step_color_white: #fff;
$step_dot_size: 15px;

.step-progress {
  display: flex;
  width: 100%;
  padding: 0 1em;
  box-sizing: border-box;
  align-items: center;
  position: relative;
  @media (max-width: 575.98px) {
    padding: 0;
  }
  .progress-track {
    width: calc(100% - 70px);
    display: flex;
    position: absolute;
    top: 9px;
    box-sizing: border-box;
    height: 2px;
    background-color: #e0e4e5;
    margin-left: 20px;
    margin-right: 20px;
    @media (max-width: 575.98px) {
      width: calc(100% - 35px);
    }
    .complete-bar {
      height: 2px;
      position: absolute;
      background-color: #9ee6df;
      z-index: 1;
      width: 0;
    }
  }
  .content-steps {
    width: 100%;
    display: flex;
    position: relative;
    z-index: 1;
    justify-content: space-between;
    .progress-step {
      align-items: center;
      display: flex;
      flex-direction: column;
      .dot {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        .inner-dot {
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: #e0e4e5;
        }
        svg {
          width: 11px;
          height: 11px;
          display: none;
          -webkit-filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.05));
          filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.05));
          polygon {
            fill: #fff;
          }
        }
      }
      .step-title {
        font-size: 11px;
        padding-top: 5px;
        color: #707c7f;
        display: none;
      }
    }
  }
  &.step1 {
    .progress-track {
      .complete-bar {
        width: 0;
      }
    }

    #step1.progress-step {
      .dot {
        background-color: #9ee6df;
        position: relative;
        top: -1px;
        animation: pulse 2s infinite, scale 1s ease-in-out;
        .inner-dot {
          opacity: 1;
          width: 6px;
          height: 6px;
          background-color: white;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
        }
      }
      span {
        color: #2e3074;
      }
    }
  }
  &.step2 {
    .progress-track {
      .complete-bar {
        width: 50%;
        transition: width 0.5s ease-in;
      }
    }
    #step2.progress-step {
      .dot {
        animation: scale 1s ease-in-out, pulse 2s infinite;
      }
    }
    #step1.progress-step {
      .dot {
        .inner-dot {
          display: none;
        }
        svg {
          display: block;
        }
      }
    }
    #step1.progress-step,
    #step2.progress-step {
      .dot {
        background-color: #9ee6df;
        position: relative;
        top: -1px;
        .inner-dot {
          width: 6px;
          height: 6px;
          background-color: white;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
        }
      }
      span {
        color: #2e3074;
      }
    }
  }
  &.step3 {
    .progress-track {
      .complete-bar {
        width: 100%;
        transition: width 0.5s ease-in;
      }
    }
    #step3.progress-step {
      .dot {
        animation: scale 1s ease-in-out, pulse 2s infinite;
      }
    }
    #step1.progress-step,
    #step2.progress-step {
      .dot {
        .inner-dot {
          display: none;
        }
        svg {
          display: block;
        }
      }
    }
    #step1.progress-step,
    #step2.progress-step,
    #step3.progress-step {
      .dot {
        background-color: #9ee6df;
        position: relative;
        top: -1px;
        .inner-dot {
          width: 6px;
          height: 6px;
          background-color: white;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
        }
      }
      span {
        color: #2e3074;
      }
    }
  }
  &.step4 {
    .progress-track {
      .complete-bar {
        width: 100%;
      }
    }
    #step1.progress-step,
    #step2.progress-step,
    #step3.progress-step {
      .dot {
        .inner-dot {
          display: none;
        }
        svg {
          display: block;
        }
      }
    }
    #step1.progress-step,
    #step2.progress-step,
    #step3.progress-step {
      .dot {
        background-color: #9ee6df;
        position: relative;
        top: -1px;
        .inner-dot {
          width: 6px;
          height: 6px;
          background-color: white;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
        }
      }
      span {
        color: #2e3074;
      }
    }
  }
  &.register {
    .content-steps {
      .progress-step {
        .step-title {
          display: none;
          &.register {
            display: block;
          }
        }
      }
    }
  }
  &.clients {
    .content-steps {
      .progress-step {
        .step-title {
          display: block;
          &.register {
            display: none;
          }
        }
      }
    }
  }
}

// Animations --------------------------------------

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(158, 230, 223, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(158, 230, 223, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(158, 230, 223, 0);
  }
}

@keyframes scale {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
