.values-header {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  padding: 20px;
  border-radius: 15px;
  .value-title {
    color: #2E3074;
  }
  .value-subtitle {
    line-height: 35px;
    font-size: 17px;
    margin: 0;
  }
}

.category-wrapper {
  flex-direction: column;
  .category-title {
    color: #2E3074;
    font-size: 25px;
    margin-left: 15px;
  }
  .category-subtitle {
    font-size: 22px;
    color: #2E3074;
  }
  .disable {
    visibility: hidden;
  }
  .icon-style {
    border-radius: 5px;
    box-shadow: 0 1px 2px 0.5px rgba(0, 0, 0, 0.5);
  }
  .card-wrapper {
    padding: 18px 15px;
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    margin: 0 0 15px 0;
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    .card-text {
      color: #2e3074;
      margin: 0 0 0 10px;
      font-size: 17px;
    }
    .card-state-text {
      color: #2e3074;
      font-size: 16px;
      margin: 0 0 0 10%;
    }
  }
}
