.grade-container-unstyled{
  background-color: transparent !important;
  padding: 0;
  height: 0 !important;
  width: fit-content !important;
}
.grade-container {
  background-color: #eaedee;
  position: relative;
  display: flex;
  width: 100%;
  height: 100px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  .grade-text {
    font-size: 38px;
    font-weight: 700px;
  }
  .grade-text-small {
    font-size: 15px !important;
    height: 33px !important;
    position: relative;
    top: 5px;
    font-weight: 700px;
  }
  .green-a-star {
    color: #0b915d;
  }
  .green-a-plus {
    color: #20b87d;
  }
  .green-a {
    color: #2acc8e;
  }
  .green-b-plus {
    color: #32db9a;
  }
  .green-b {
    color: #b1d94f;
  }
  .orange-c-plus {
    color: #f7a000;
  }
  .orange-c {
    color: #f78900;
  }
  .orange-d {
    color: #fa6a00;
  }
  .red-e {
    color: #e02020;
  }
  .red-f {
    color: #b91414;
  }
  .NA {
    color: #3e61df;
  }
}
.grades-related-investment {
  height: 32px;
  .grade-text {
    font-size: 14px !important;
  }
}

.grades-related-search {
  height: 28px !important;
  width: 3em !important;
  border-radius: 5px !important;
  margin: auto;
  .grade-text {
    font-size: 14px !important;
  }
}
