.modal-filter-sort-body {
  label {
    // padding: 0 2em;
    padding-bottom: 0.5em;
    cursor: pointer;
  }
  input {
    position: relative;
    width: 15px;
    top: -0.3em;
  }
  .filter-icon {
    position: relative;
    bottom: 2px;
  }
  .filter-icon {
    img {
      width: 16px;
      height: 16px;
      position: relative;
      left: 1.1em;
    }
  }
}
.modal-label {
  flex-wrap: wrap;
  max-width: 92%;
  font-size: 15px;
}

.modal-input {
  margin-left: auto;
}
.search-common-filters {
  margin: 0 -18px;
}
.advanced-filters {
  margin-top: 20px;
  margin-left: -15px;
  color: #2e3074;
}
