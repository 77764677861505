.register-steps {
  &.step-4 {
    .content-plans-select {
      .custom-checks {
        display: flex;
        width: 680px;
        justify-content: space-between;
        margin: 1em 0;
        position: relative;
        left: -35px;
        @media (max-width: 575.98px) {
          left: 0;
        }
        .col-sm-6 {
          display: flex;
          justify-content: center;
        }
        .form-check {
          width: 328px;
          padding: 0;
        }
        @media (max-width: 768px) {
          margin: 0;
          width: 100%;
        }
        [type='radio']:checked,
        [type='radio']:not(:checked) {
          position: absolute;
          left: -9999px;
        }
        [type='radio']:checked + label,
        [type='radio']:not(:checked) + label {
          position: relative;
          padding-left: 69px;
          cursor: pointer;
          line-height: 20px;
          display: flex;
          color: #2e3074;
          align-items: center;
          box-sizing: border-box;
          user-select: none;
          height: 181px;
          width: 328px;
          border-radius: 10px;
          background-color: #d4d9da;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          overflow: hidden;
          .description {
            font-size: 13px;
            &:first-letter {
              text-transform: capitalize;
            }
          }
          .title-suscription {
            color: #2e3074;
            font-size: 18px;
            display: block;
            margin-bottom: 16px;
          }
          .price {
            display: block;
            color: #2e3074;
            font-size: 37px;
            margin-bottom: 16px;
            position: relative;
            sup {
              color: #2e3074;
              font-size: 16px;
              position: absolute;
              top: 3px;
              width: 100px;
              padding-left: 5px;
            }
          }
          .banner {
            height: 31px;
            width: 170px;
            opacity: 0.73;
            transform: rotate(45deg);
            background-color: #ffffff;
            color: #2e3074;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            right: -37px;
            top: 33px;
            font-size: 1em;
            font-weight: 600;
          }
        }
        [type='radio']:checked + label:before,
        [type='radio']:not(:checked) + label:before {
          content: '';
          position: absolute;
          left: 18px;
          top: 60px;
          width: 35px;
          height: 35px;
          border-radius: 100%;
          background: #fff;
        }
        [type='radio']:checked + label:after,
        [type='radio']:not(:checked) + label:after {
          content: '';
          width: 15px;
          height: 15px;
          background: #222121;
          position: absolute;
          top: 70px;
          left: 28px;
          border-radius: 100%;
          -webkit-transition: all 0.2s ease;
          transition: all 0.2s ease;
        }
        [type='radio']:not(:checked) + label:after {
          opacity: 0;
          -webkit-transform: scale(0);
          transform: scale(0);
        }
        [type='radio']:checked + label {
          background: linear-gradient(139.12deg, #3e61df 0%, #36d1dc 100%);
          box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
          color: #fff;
          .title-suscription {
            color: #fff;
          }
          .price {
            color: #fff;
            sup {
              color: #fff;
            }
          }
          .description {
            color: #fff;
            font-size: 13px;
          }
        }
        [type='radio']:checked + label:after {
          opacity: 1;
          -webkit-transform: scale(1);
          transform: scale(1);
        }
      }
    }
  }
  .payment-text,
  a {
    font-size: 17px;
    letter-spacing: -0.36px;
    line-height: 22px;
  }
}
