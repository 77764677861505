@import '../../scss/variables';

$path_img: '../../src/_assets/img';

.header {
  display: flex;
  align-items: center;
  top: 0;
  padding: 14px;
  margin: 0;
  box-sizing: border-box;
  height: 60px;
  justify-content: space-between;

  .header__left {
    padding-left: 20px;
  }
  .logo-btn{
    display: inline-flex;
    gap: 8px;
    align-items: flex-end;
  }

  .menu-hamburger {
    display: none;
    @media (max-width: 768px) {
      display: inline-block;
      padding: 10px;
      width: 32px;
      height: 32px;
      background-image: url('../../_assets/img/icons/nav/side/list@3x.png');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      cursor: pointer;
    }
  }

  .header__center {
    margin: 0 2em;
    align-items: center;
    display: flex;
    justify-content: center;
    display: block;
    flex-grow: 1;
    @media (max-width: 575.98px) {
      display: none;
    }
    .desktop-only {
      padding: 0;
      margin: 0;
    }
    ul {
      display: block;
      margin: 0;
      position: relative;
      top: 3px;

      li {
        display: inline-block;
        margin: 0 1em;
        @media (max-width: 768px) {
          margin-left: 0;
        }

        .nav-link {
          color: $white !important;
          font-size: 15px;

          &:hover,
          &.active {
            color: #7ce0d3 !important;
            text-decoration: none;
          }
          &.active {
            font-weight: bold;
          }
        }
      }
    }
    .menu-mobile {
      opacity: 0;
      transition: opacity 0.35s ease-in-out;
      -moz-transition: opacity 0.35s ease-in-out;
      -webkit-transition: opacity 0.35s ease-in-out;
      position: fixed;
      z-index: 0;
      //display: block !important;
      margin-top: 14px;
      font-size: 1.2em;
      clear: both;
      width: 100%;
      height: 0;
      right: 0;
      text-align: right;
      padding: 20px;
    }
  }

  .header__right {
    display: flex;
    justify-content: space-around;
    width: 330px;
    @media (max-width: 575.98px) {
      width: 85px;
    }
    .content-buttons {
      display: flex;
      .profile-logo {
        transform: scale(1.3);
      }
      .active-logo {
        position: absolute;
        bottom: 8px;
        left: 87px;
      }
      .nav {
        .nav-item {
          margin-left: 1.2em;
          .nav-link {
            padding: 0;
            color: #fff;
          }
          &:last-child {
            margin-left: 20px;
          }
          &.profile-menu {
            display: flex;
            justify-content: center;
            align-items: center;
            a {
              display: flex;
              &.active {
                span {
                  color: #2e3074;
                }
                img {
                  filter: brightness(0) saturate(100%) invert(21%) sepia(28%) saturate(2412%)
                    hue-rotate(210deg) brightness(86%) contrast(95%);
                }
              }
            }
            span {
              color: $white;
              font-size: 15px;
              user-select: none;
              padding-left: 10px;
            }
          }
        }
      }
      img {
        height: 22px;
      }
    }
    .custom-control {
      margin-right: 25px;
    }
    .custom-control-input:checked ~ .custom-control-label::before {
      background-color: #7ce0d3;
      border: 0.5px solid #adb5bd;
    }
    label {
      list-style: none;
    }
  }
  .menu-active {
    opacity: 1;
    height: 100%;
    position: fixed;
    z-index: 999;
    display: block !important;
    margin-top: 14px;
    font-size: 1.2em;
    clear: both;
    width: 100%;
    right: 0;
    text-align: right;
    padding: 20px;
    @media (min-width: 768px) {
      display: none !important;
    }

    li {
      display: block;
      clear: both;

      a:hover {
        color: #333;
      }
    }
  }
}

.background-gradient {
  background: rgba(62, 107, 222, 1);
  background: url('../../_assets/img/header/watermarkShapes.png') no-repeat center center,
    -moz-linear-gradient(left, rgba(62, 107, 222, 1) 0%, rgba(62, 107, 222, 1) 36%, rgba(
            79,
            192,
            223,
            1
          )
          92%, rgba(79, 192, 223, 1) 100%);
  background: url('../../_assets/img/header/watermarkShapes.png') no-repeat center center,
    -webkit-linear-gradient(left, rgba(62, 107, 222, 1) 0%, rgba(62, 107, 222, 1) 36%, rgba(
            79,
            192,
            223,
            1
          )
          92%, rgba(79, 192, 223, 1) 100%);
  background: url('../../_assets/img/header/watermarkShapes.png') no-repeat center center,
    -o-linear-gradient(left, rgba(62, 107, 222, 1) 0%, rgba(62, 107, 222, 1) 36%, rgba(
            79,
            192,
            223,
            1
          )
          92%, rgba(79, 192, 223, 1) 100%);
  background: url('../../_assets/img/header/watermarkShapes.png') no-repeat center center,
    -ms-linear-gradient(left, rgba(62, 107, 222, 1) 0%, rgba(62, 107, 222, 1) 36%, rgba(
            79,
            192,
            223,
            1
          )
          92%, rgba(79, 192, 223, 1) 100%);
  background: url('../../_assets/img/header/watermarkShapes.png') no-repeat center center,
    linear-gradient(
      to right,
      rgba(62, 107, 222, 1) 0%,
      rgba(62, 107, 222, 1) 36%,
      rgba(79, 192, 223, 1) 92%,
      rgba(79, 192, 223, 1) 100%
    );
  background-size: cover;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3e6bde', endColorstr='#4fc0df', GradientType=1);
}

// Mobile menu

#nav-icon {
  width: 60px;
  height: 45px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  transform: scale(0.35);
  margin-left: -15px;
}

.title-flex {
  @media (max-width: 575.98px) {
    padding-right: 0;
  }
  & + .header__center {
    display: none;
  }
}

#nav-icon span {
  display: block;
  position: absolute;
  height: 5px;
  width: 50%;
  background: $white;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#nav-icon span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0;
}

#nav-icon span:nth-child(odd) {
  left: 0px;
  border-radius: 9px 0 0 9px;
}

#nav-icon span:nth-child(1),
#nav-icon span:nth-child(2) {
  top: 0px;
}

#nav-icon span:nth-child(3),
#nav-icon span:nth-child(4) {
  top: 20px;
}

#nav-icon span:nth-child(5),
#nav-icon span:nth-child(6) {
  top: 40px;
}

body.menu-active {
  #nav-icon span:nth-child(1),
  #nav-icon span:nth-child(6) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  #nav-icon span:nth-child(2),
  #nav-icon span:nth-child(5) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  #nav-icon span:nth-child(1) {
    left: 5px;
    top: 7px;
  }

  #nav-icon span:nth-child(2) {
    left: calc(50% - 5px);
    top: 7px;
  }

  #nav-icon span:nth-child(3) {
    left: -50%;
    opacity: 0;
  }

  #nav-icon span:nth-child(4) {
    left: 100%;
    opacity: 0;
  }

  #nav-icon span:nth-child(5) {
    left: 5px;
    top: 29px;
  }

  #nav-icon span:nth-child(6) {
    left: calc(50% - 5px);
    top: 29px;
  }
}

.strong-text {
  font-size: 22px;
  font-weight: 900;
  color: #333 !important;
}

.modal-width {
  max-width: 750px !important;
  position: relative;
}

.close-button {
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
}
