@import '../../../../../src/scss/variables';

.results-profile-portfolio {
  justify-content: center;
  display: flex;
  position: relative;

  .isSelected {
    background: #7be0d3;
  }

  .tile-col {
    padding-left: 0;
    padding-right: 0;
  }

  .tile-col > div {
    box-sizing: border-box;
    padding: 0;
  }

  .tile {
    // width: 20%;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    display: flex;
    margin-right: 5px;
    &:hover,
    &.active {
      background: #7be0d3;
    }
    .custom-row {
      align-items: center;
      display: flex;

      .img {
        width: 45px;
      }

      .text-col {
        font-size: 14px;
        line-height: 18px;
        color: $primary-accent-color;

        span {
          font-size: 14px;
          font-family: $title-font-family;
          font-weight: bolder;
          color: $primary-accent-color;
        }
      }
    }
    .add-col {
      flex-grow: 1;
      justify-content: flex-end;
      display: flex;
      padding-top: 5px;
      padding-right: 8px;

      .img {
        width: 20px;
      }
    }
  }
}

.filter-and-sort {
  font-size: 15px !important;
  color: #2e3074;
}

.load-more {
  height: 42px;
  width: 263px;
  border-radius: 8px;
  background-color: #f9f9f9;
  border-width: thin;
}

.column {
  position: relative;
  left: -50px;
}
.match {
  border-radius: 0.25rem;
  // width: 90px;
  color: white;
  font-size: 14px;
}

.progress {
  margin: 15px auto;
  padding: 0.5px;
  width: 50%;
  height: 5px;
  overflow: hidden;
  background: #eeeded;
  border-radius: 0.25rem;
  justify-content: flex-start;
}

.bar {
  position: relative;
  float: left;
  min-width: 1%;
  height: 100%;
  border-radius: 0.25rem;
  background-color: #3e61df;
  background-image: linear-gradient(to right, #3e61df, #9ce0d7);
}

.tipCard {
  width: fit-content;
  white-space: nowrap;
  margin: 0 auto;
  color: #333 !important;
}
.common-filters {
  position: relative;
  top: 80px;
  width: 560px;
  left: 210px;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.max-width-1100 {
  max-width: 1100px !important;
}

.instrument-symbol {
  position: relative;
  left: 11px;
}
