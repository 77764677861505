$height: 134px;
.tips-panel-compare {
  // height: fit-content;
  height: $height;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 8px;
  padding-top: 20px;
  padding-bottom: 6px;
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.tips-panel-compare img {
  width: 7%;
  width: 10%;
  margin-right: 10px;
  margin-bottom: 14px;
  margin-bottom: 14px;
}
.tips-panel-compare p {
  flex: 1;
}
.tips-icon {
  float: left;
  margin-top: 20px;
}
.highlight {
  background-color: rgba(213, 245, 241, 0.2);
  .sector-text {
    white-space: nowrap;
    width: 95%;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .instrument-type {
    text-transform: capitalize;
  }
  .uppercase {
    text-transform: uppercase;
  }
}
.title-logo {
  padding: 5px;
  width: 74px;
  height: 74px;
  border-radius: 35px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(45, 190, 171, 0.2);
}
.overview {
  border-radius: 10px;
  border: 1px solid #eee;
  h5 {
    font-weight: bold;
  }
  .profile-item {
    border-bottom: 1px solid #eee;
    padding: 10px;
    &.heading {
      border-bottom: none;
    }
    .status {
      padding-top: 0.1rem;
      padding-bottom: 0.1rem;
      height: auto;
      margin-bottom: 0;
      border: none;
    }
  }
  &-title {
    height: $height;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 10px;
    .title-text {
      white-space: nowrap;
      width: 80%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  h4 {
    font-size: 15px;
    padding-top: 15px;
  }
  .span {
    font-size: 17px;
  }
  h4 {
    font-size: 15px;
    padding-top: 15px;
  }
  .span {
    font-size: 17px;
  }
  .remove-text {
    font-size: 12px;
    color: #3e61df;
    margin: 0;
    position: absolute;
    top: 120px;
    cursor: pointer;
  }
}
.detail {
  display: flex;
  padding-left: 10px;
  flex-wrap: nowrap;
  .title {
    flex: 1;
    width: 345px;
  }
  .description {
    margin-right: 10px;
    width: 175px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .overview {
      width: 100%;
    }
  }
}
#btn-view-detail {
  background: #3e61df !important;
  border: none;
  color: white !important;
  max-height: 32px !important;
  padding: 0 !important;
  font-size: 15px;
  max-width: 154px;
  max-height: 32px !important;
  padding: 0 !important;
  font-size: 15px;
  max-width: 154px;
}
// div {
//   border-top: 3px solid #bbb;
// }
.profile-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0;
  height: 45px;
}
.profile-item.heading {
  height: 60px;
  padding-top: 20px;
}
.profile-item.heading h5 {
  font-weight: bold;
}
.profile-selection {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-weight: 600;
  justify-content: flex-end;
  text-align: right;
  .profile-selection-text {
    font-size: 12px;
    color: #2e3074;
    margin-bottom: 0;
  }
}
.grade-compare {
  background-color: white !important;
  min-height: 10px;
  max-height: 31px !important;
  .grade-text{
    font-size: 19px !important;

  }
}

.description .profile-item {
  justify-content: center;
}
.profile-item-match {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 0;
  height: 20px;
  border-radius: 0.25rem;
  width: 70px;
  color: white;
  font-size: 14px;
}

.remove-box-top {
  height: 45px;
  line-height: 20px;
  background-color: #3e61df;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.remove-box-text {
  font-size: 15px;
  padding: 0 10px;
}

.remove-box-cancel {
  font-size: 12px;
  position: absolute;
  bottom: 12px;
  right: 115px;
  color: #3e61df !important;
  cursor: pointer;
}

.remove-box-button {
  font-size: 15px;
  width: 95px;
  height: 22px;
  background-color: #3e61df;
  border-radius: 26px;
  color: #fff;
  text-align: center;
  position: absolute;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
}

.clear-all-text {
  color: #3e61df;
  font-size: 15px;
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
}

.Apl-tag {
  height: 15px;
  width: 28px;
  background-color: white;
  border-radius: 40%;
  color: #49cfbd;
  box-shadow: 0.5px 1px 1px 1px rgba(175, 173, 173, 0.5);
  text-align: center;
  font-weight: bold !important;
  font-size: 9px;
  padding: 1px 3px;
  position: absolute;
  top: 5px;
  right: 40px;
}
