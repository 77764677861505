@import '../../../../_assets/css/variables';

.company-client {
  position: relative;
  z-index: 1;
  .alert-info {
    border-color: transparent;
    background-color: rgba(123, 224, 211, 0.2);
    h5 {
      margin: 0;
    }
  }
}

.title-score {
  display: flex;
  width: 100%;
  font-size: 25px;
  font-weight: 500;
  letter-spacing: -0.54px;
  color: #2e3074;
  span {
    color: #2e3074 !important;
    letter-spacing: -0.54px;
    font-weight: 500;
    font-size: 25px;
  }
  .icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  &.center {
    justify-content: center;
    align-items: center;
  }
}

.match-percentage {
  display: flex;
  font-size: 14px;
  font-weight: 200;
  font-family: $default-font;
  letter-spacing: -0.27px;
  color: #2e3074;
  background-color: #edf1ff;
  line-height: 24px;
  text-align: center;
  padding: 2px;
  padding-left: 6px;
  padding-right: 6px;
  margin-left: 14px;
  border-radius: 8px;
}

.why-is {
  height: 31px;
  width: 233px;
  color: #2e3074 !important;
  font-size: 25px;
  font-weight: bold;
  letter-spacing: -0.54px;
  line-height: 31px;
  text-align: center;
}
.content-gray-pills {
  margin-bottom: 46px;
}

.gray-pill,
.red-pill,
.green-pill {
  position: relative;
  background-color: #f6f6f6;
  padding: 1em;
  border-radius: 8px;
  border: 0.8px solid #f8f8f8;
  h5 {
    color: #000;
    text-align: left;
    padding-left: 1em;
    font-size: 16px;
  }
  button.hide-button {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    max-height: 200px !important;
    max-width: 500px !important;
    top: 0;
    right: 0;
    opacity: 0;
  }
}
.green-pill {
  background-color: #e6fff2;
  padding: 1em;
  border-radius: 6px;
  margin-top: 16px;
  h5 {
    color: #000;
    text-align: left;
    padding-left: 1em;
    font-size: 16px;
  }
}
.red-pill {
  background-color: #ffe5ea;
  padding: 1em;
  border-radius: 6px;
  h5 {
    color: #000;
    text-align: left;
    padding-left: 1em;
    font-size: 16px;
  }
}

table > tbody > tr > td > div > div.status-pill {
  padding: 0.5rem 0;
  margin-bottom: 0px;
  line-height: 18px;
  min-width: 90px;
  height: 22px;
}

.filter-tag {
  height: 20px;
  width: 20px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: -15px;
  left: 2px;
  color: #51d4c3;
  font-size: 12px;
  box-shadow: 0px 5px 10px 0px rgba(175, 173, 173, 0.29);
  padding: 2px 1px 1px 2px;
  text-align: center;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
