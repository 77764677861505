.tooltip-container {
  width: 120px;
  height: 110px;
  border-radius: 20px;
  padding: 10px;
  box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 20px;
  .tooltip-button {
    border: none;
    background-color: #fff;
    position: absolute;
    top: 3px;
    right: 15px;
    padding: 0;
    font-size: 14px;
  }
  .tooltip-text {
    font-size: 13px;
    margin: 0;
    text-align: center;
    line-height: 15px;
    margin-top: 10px;
    margin-bottom: 5px;
  }
}

.tooltip-arrow {
  position: absolute;
  top: 20px;
  right: 140px;
}
