.btn-addon {
  button {
    height: 40px;
    padding: 6px !important;
    background-color: #a4e6df !important;
    min-width: 140px !important;
    color: #2e3074 !important;
    &:hover {
      color: #2e3074 !important;
    }
  }
}
