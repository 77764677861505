.score-percent{
  font-size: 12px;
  color: #2e3074;
}
.score-amount{
  font-size: 30px;
  font-weight: bold;
  color: #2e3074;
}
.doughnut-wrapper{
  & canvas{
    transform: rotate(-90deg);
  }
}
.donut-text-inner {
  color: #2e3074;
}