.welcome {
  .welcome-title {
    margin-left: 10px;
    font-size: 32px;
    color: black;
    // color: RGB(46, 48, 116);
  }
  .welcome-subtitle {
    position: relative;
    margin-left: 10px;
    z-index: 1;
    font-size: 22px;
    line-height: 25px;
  }
  .welcome-text {
    font-size: 18px;
    line-height: 24px;
    // color:RGB(46, 48, 116);
    .welcome-code {
      font-weight: 900;
      font-size: 18px;
    }
  }
  .tip {
    font-size: 15px;
  }
}
.welcome-img{
  width: 441px;
  height: 359px;
  padding-left: 55px;
  opacity: 100%;
}
