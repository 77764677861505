// .status-pill {
//   margin-bottom: 0;
//   padding-top: 0.1rem !important;
//   padding-bottom: 0.1rem !important;
//   border: none !important;
//   line-height: 28px;
// }
.partial-status-pill {
  height: 22px !important;
  line-height: 22px !important;
  border-radius: 10px !important;
}
.table-partial-status-pill {
  height: 32px !important;
  line-height: 28px !important;
}
