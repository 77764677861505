.success-payment {
  width: 672px !important;
  margin-left: -46px !important;
  @media (max-width: 575.98px) {
    width: 100% !important;
    margin-left: 0 !important;
    padding: 0 !important;
  }
  .success-payment-row {
    background-color: #ececec;
    padding: 28px 100px;
    border-radius: 10px;
    margin-top: 60px;
    margin-bottom: 40px;
    @media (max-width: 575.98px) {
      margin-top: 20px;
      padding: 20px;
    }
  }
}
