.sustainability-overview-container {
  width: 100%;
  max-width: 1300px !important;
}

.title {
  font-size: 25px;
  color: #2e3074;
  font-weight: 300 !important;
}

.subtitle {
  font-size: 17px !important;
  margin-bottom: 0;
  font-weight: 100 !important;
}

.donut-text {
  text-align: center;
}

.tipcard {
  width: 90%;
  white-space: nowrap;
  margin: 0 auto;
  color: #333 !important;
  font-size: 17px;
  word-spacing: 1.1px;
  padding: 10px;
}

.tipcard-link {
  float: right;
  margin-right: 30px;
  cursor: pointer;
}

.donut-container {
  width: 100%;
  height: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
  &.circle {
    width: 188px;
    height: 188px;
  }
  &.smaller-circle {
    width: 138px;
    height: 120px;
  }
  &.line-chart {
    height: 432px;
    margin-bottom: 2em;
    canvas {
      border: 1px solid #eaedee;
      border-top: none;
      border-right: none;
    }
  }
}
.risk-and-return-chart-container {
  width: 100%;
  height: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
  &.circle {
    width: 188px;
    height: 188px;
  }
  &.smaller-circle {
    width: 138px;
    height: 120px;
  }
  &.line-chart {
    canvas {
      border: 1px solid #eaedee;
      border-top: none;
      border-right: none;
    }
  }
}

.icon {
  background-color: #e5f9f6;
  width: 56px;
  height: 53px;
  border-radius: 50%;
  padding: 3px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
  margin-right: 15px;
}

.avoid {
  background-color: #ffe5ea;
}
.unselected {
  background-color: white;
  margin-bottom: 15px;
}

.portfolio-weighting-row {
  display: flex;
  margin-top: 40px;
  gap: 30px;
  justify-content: space-evenly;
  align-items: flex-end;
}

.doughnut-container {
  position: relative;
}

.doughnut-chart {
  height: 352px;
  width: 357px;
  position: relative;
}

.legend {
  height: 130px;
  width: 130px;
  border-radius: 50%;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
  padding: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  position: absolute;
}

.legend.left {
  bottom: 103px;
  left: -80px;
}

.legend.right {
  bottom: 200px;
  left: 250px;
}

// .porfolio-weight-legend > div:not(:last-child) {
//   margin-bottom: 5px;
// }

.legend > div:nth-child(2) {
  margin-bottom: 5px;
}

.legend > .avoid,
.legend > .support {
  width: 83px;
  height: 22px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  line-height: 20px;
}

.legend > .avoid {
  background: #bd5f80;
  color: white;
}

.legend > .support {
  background: #7be0d3;
}

.legend > .holdings {
  font-size: 14px;
  line-height: 21px;
  color: #2e3074;
}

.legend > .percentage {
  font-size: 20px;
  line-height: 25.5px;
  color: #2e3074;
  font-weight: 500px;
}

.portfolio-weight-tipcard {
  display: flex;
  justify-content: flex-end;
}
.portfolio-weight-tipcard > .weight-tipcard {
  width: 35%;
}
.portfolio-doughnut-container{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 30px;
}
.sustainability-container{
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  padding: 30px;
  border-radius: 15px;
  margin-left: auto;
  margin-right: auto;
  width: 95%;
  max-width: 1100px !important;
}
@media(max-width: 1396px){
  .portfolio-left-div{
    display: none;
  }
  .portfolio-weighting-row{
    flex-direction: column;
    align-items: center;
  }
}
