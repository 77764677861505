.layout-container {
  background: $white;
  display: flex;
  // height: calc(100vh - 60px);

  .content-with-sidebar {
    display: flex;
    position: relative;
    background: $white;
    padding: 0 14px 40px;
    width: 100%;
    overflow: auto;

    @media (max-width: 768px) {
      position: relative;
      height: auto;
      width: 100%;
      margin-left: 0px;
      padding: 0 14px 100px;
      display: block;
    }
  }

  .content {
    position: relative;
    width: 100%;
    height: fit-content;
  }

  .title {
    text-align: left;
    font-family: $title-font-family;
  }

  .sub-title {
    text-align: left;
    font-family: $title-font-family;
  }
}

html,
body,
div,
p,
span,
*,
a,
input,
button,
label,
small {
  color: #313132;
}
