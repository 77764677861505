.search-page {
  width: 90%;
  margin: 0 auto;
}
.search-heading {
  margin-left: 0.25em;
}

.search-type {
  width: 9em;
  padding: 0.5em;
  color: #2e3074;
  &.symbol {
    text-align: center;
  }
  &:hover,
  &.selected {
    border-radius: 5px;
    background-color: #e5f9f6 !important;
    margin: 0;
  }
}

.search-container {
  height: 3.8em;
  background-color: #eeeff2;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 8fr 1fr;
  gap: 5px;
}

.search-box {
  margin: 0.5em 0 0.5em 0.5em;
  background-color: white;
  border-radius: 5px;
  text-align: center;
  // flex: 1;
}

input.search-input {
  border: none;
  height: 100%;
  width: 90%;
  box-shadow: none !important;
  padding-left: 1em;
}

input:focus {
  outline: none;
}

.search {
  float: right;
  margin: auto;
}
.search-clear,
.printer,
.filter {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
.filter-box,
.printer-box {
  background-color: #ffffff;
  border-radius: 5px;
  justify-content: center;
}
.filter-box {
  // margin: 0.5em 0;
  margin: 0.5em 0.5em 0.5em 0;
}
.printer-box {
  margin: 0.5em 0.5em 0.5em 0;
}

.clear-text {
  font-size: 0.9em;
  color: #2e3074;
  display: flex;
  align-items: center;
}
.filter-icon,
.print-icon {
  margin-right: 1em;
}

.filter,
.print {
  color: lightgrey;
}

.search-popup-heading {
  display: grid;
  grid-template-columns: 2.5fr 1fr 1fr 1fr 1fr 1fr 1fr;
  background-color: #f8f9fa;
  border-radius: 5px;
  height: 3em;
}

.name,
.search-popup {
  text-align: left;
  align-self: center;
  color: #2e3074;
  white-space: nowrap;
  position: relative;
  display: inline-block;
  &.match-text {
    text-align: center !important;
  }
  &.compare {
    justify-content: center;
  }
}

.name {
  margin-left: 1em;
}

.compare-count {
  position: relative;
  top: -0.5em;
}

.circle {
  align-items: center;
  justify-content: center;
  min-width: 17px !important;
  min-height: 17px !important;
  font-size: 12px !important;
}

img.filter-image,
img.clear-image {
  margin-left: 0.5em;
}

.search-result-list {
  display: grid;
  grid-template-columns: 2.5fr 1fr 1fr 1fr 1fr 1fr 1fr;
  text-align: left;
}

.search-result-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-result {
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
}

.compare-text {
  text-align: center;
  color: #2e3074;
}

.compare {
  display: flex;
  justify-content: center;
  align-items: center;
}
