$company-color: #2e3074;

.company {
  .text-company-primary {
    color: $company-color;
  }

  .content-with-sidebar,
  .content {
    position: relative;
    .instrument-info {
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100vw - 25vh);
        max-height: 500px;
        background: linear-gradient(313.84deg, #3e61df 0%, #2e3074 100%);
        @media (max-width: 575.98px) {
          height: 100vh;
        }
      }
    }

    .top-buttons {
      position: absolute;
      padding: 10px;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1;
    }

    .company-data {
      z-index: 1;
      width: 677px;
      margin: 0 auto;
      @media (max-width: 575.98px) {
        width: 100%;
      }

      p {
        margin-bottom: 5px;
        color: white;
        font-size: 22px;
        line-height: 27px;
        border-bottom: 1px solid lightslategray;
      }

      span {
        color: white;
        font-size: 12px;
        line-height: 14px;
      }
    }

    .company-logo-content {
      .company-logo {
        border-radius: 50%;
        height: 107px;
        width: 107px;
        border: 1px solid white;
      }
    }

    .company-score {
      z-index: 1;
      margin: 0 auto;
      @media (max-width: 575.98px) {
        width: calc(100% + 50px);
        margin: 0 -25px;
      }

      .title-score {
        color: $company-color;
        font-size: 25px;
        font-weight: 500;
        letter-spacing: -0.54px;
        color: #2e3074;
        margin-top: 41px;

        span {
          color: #2e3074 !important;
          letter-spacing: -0.54px;
          font-weight: 500;
          font-size: 25px;
        }
      }

      .card-body {
        margin-top: -1em;
      }

      .card-body {
        display: flex;
        justify-content: center;

        .content-gray-pills {
          margin-bottom: 46px;
        }

        .gray-pill,
        .green-pill,
        .red-pill {
          width: 402px;
          border-radius: 8px;
          margin-top: 16px;
          border: 0.8px solid #f8f8f8;
          margin-bottom: 14px !important;
          @media (max-width: 575.98px) {
            width: 100%;
          }
        }
      }

      .btn-main-shadowless {
        @media (max-width: 575.98px) {
          padding: 20px;
          margin-bottom: 20px;
        }
      }
    }

    .logo-and-title {
      position: relative;
      z-index: 1;
      margin-top: 18px;

      h2 {
        color: white;
      }

      h3 {
        color: $company-color;
      }
    }

    .company-card {
      position: relative;
      z-index: 1;
    }
  }
}

#viewMoreText {
  display: inline-block;
  height: 120px;
  overflow: hidden;
  padding: 0 1em;
  position: relative;

  &.show {
    height: auto;

    .read-more {
      display: none;

      &.less {
        display: block;
      }
    }
  }

  .read-more {
    position: absolute;
    bottom: 0px;
    background-color: #fff;
    right: 0;
    font-size: 17px;
    padding: 0 20px 0 5px;
    color: #3e61df !important;

    &.witch-chevron {
      &:after {
        position: absolute;
        content: '<';
        display: block;
        color: #3e61df;
        right: 2px;
        font-weight: bold;
        top: 6px;
        transform: scaleY(-1) rotate(90deg);
        width: auto;
        height: 16px;
      }
    }

    &.less {
      display: none;

      &.witch-chevron {
        &:after {
          transform: scaleY(-1) rotate(270deg);
          right: 10px;
        }
      }
    }
  }
}

.blue-text {
  color: #3e61df;
}

.table-rounded {
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #dee2e6;

  table {
    .gray {
      background-color: #f8f8f8;
    }

    thead {
      tr {
        th {
          border-top: 0;
          border-bottom: 0;

          &:first-child {
            border-left: 0;
          }

          &:last-child {
            border-right: 0;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          border-bottom: 0;

          &:first-child {
            border-left: 0;
          }

          &:last-child {
            border-right: 0;
          }
        }
      }
    }
  }
}

.company .content-with-sidebar .container {
  padding: 0;
  max-width: 678px !important;

  .company-score .col-sm-12 {
    padding: 0;
  }
}

.apl-tag {
  height: 20px;
  width: 20px;
  background-color: white;
  border-radius: 25%;
  color: #5becd9;
  font-size: 12px;
  box-shadow: 1px 2px 2px 2px rgba(175, 173, 173, 0.29);
  text-align: center;
  font-weight: bold;
  font-size: 10px;
  padding: 1px 4px;
}

.apl-tag-results {
  height: 15px;
  width: 15px;
  background-color: white;
  border-radius: 25%;
  color: #49cfbd;
  box-shadow: 1px 2px 2px 2px rgba(175, 173, 173, 0.29);
  text-align: center;
  font-weight: bold;
  font-size: 8px;
  padding: 1px 3px;
  position: relative;
  top: -25px;
  left: -2px;
}
li > a[href='/clients'] {
  display: none;
}

.checkbox-header {
  position: absolute;
  color: #2e3074;
  top: -20px;
  right: -8px;
  z-index: 0;
  padding: 0 !important;
  font-size: 16px !important;
}

.checkbox-count {
  position: absolute;
  top: -30px;
  right: 52px;
  z-index: 1;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.15);
  font-size: 12px;
  color: #51d4c3;
  font-weight: 700;
  text-align: center;
  padding: 0 !important;
}

.checkbox-count-result {
  position: absolute;
  z-index: 1;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.15);
  font-size: 12px;
  color: #51d4c3;
  font-weight: 700;
  text-align: center;
  right: 80px;
  top: 280px;
}

.research-checkbox {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  display: grid;
  place-content: center;
  font: inherit;
  color: #e2e1e3;
  width: 1.35em;
  height: 1.35em;
  border: 0.15em solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  cursor: pointer;
  border-radius: 50%;
  &.search {
    width: 1.8em;
    height: 1.8em;
    border: 0.3em solid;
  }
}

.form-control-checkbox + .form-control-checkbox {
  margin-top: 1em;
}

.research-checkbox::before {
  content: '';
  width: 0.95em;
  height: 0.95em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #dff9f3;
  border-radius: 50%;
}

.research-checkbox:checked::before {
  transform: scale(1);
}

.search-status-pill {
  width: 9em;
  height: 2.3em;
  font-size: 14px;
  border: radius 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

img.popup-image {
  margin-left: 0.5em;
}
