@font-face {
  font-family: 'Chapeau-Regular';
  src: url('../_assets/fonts/Chapeau-Regular.ttf') format('opentype');
}
@font-face {
  font-family: 'Chapeau-Medium';
  src: url('../_assets/fonts/Chapeau-Medium.ttf') format('opentype');
}

// Colors
$greyf6: #f6f6f6;
$greyf8: #f8f8f8;
$site-background: #f8f8f8;
$dark-grey: #d6d8d9;
$white: #ffffff;
// $sidebar-background: #f7fbff;
$sidebar-background: #f8f8f8;
$white-hover: #4fc0df;
$input-focus: #80bdff;
$input-shadow: rgba(0, 123, 255, 0.25);
$input-error: #dc3545;
$icon-active: #7be0d3;
$icon-inactive: #dfe4ea;
$header-font-color: #2e3074;
$primary-accent-color: #2e3074;
$primary-accent-color-hover: #50c2dc;
$link-color: #3e61df;
$link-color-sidebar: #313132;
$link-color-sidebar-active: #3e61df;
$button-color: #3e61df;
$button-hover: darken(#a4e6df, 20%);

$default-font: 'Chapeau-Regular', sans-serif;
// gradient
$gradient-login: rgb(62, 98, 223);
$gradient-login-1: rgba(62, 98, 223, 1);
$gradient-login-2: rgba(78, 190, 221, 1);
// Sizes
$border-radius: 0.25rem;
$header-height: 60px;

$title-font-family: 'Chapeau-Medium';

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}
