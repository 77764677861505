.instrument {
  background-color: #f7f7f7;

  .tabs {
    border-radius: 8px;
    background-color: #ffffff;

    li {
      width: 48%;

      &.active {
        background-color: #e5f9f6;
        border-radius: 8px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
      }
    }
  }

  .advice-output {
    background-color: white;
    margin-top: 5px;
    border-radius: 8px;
    box-shadow: 0 0 5px 0 #eaedee;

    .content-advice-output-btns {
      display: flex;
      justify-content: center;

      button {
        margin: 0 5px;
      }
    }
  }

  .no-data {
    width: 385px;
    border-radius: 8px;
    background-color: #f8f8f8;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    height: 67px;
    text-align: center;
    padding: 22px;
    margin: 50px auto;
  }

  .instrument-status {
    .conflict {
      background-color: #ffe5ea;
    }

    .match {
      background-color: #e5f9f6;
      width: 56px;
      height: 53px;
      border-radius: 50%;
      padding: 3px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
    }
  }
  .related-issues-nodata {
    min-height: 21.2rem;
    flex-grow: 1;
  }

  .table-benchmark {
    overflow: hidden;
    border-radius: 10px;
    border: 0px solid #dee2e6;

    table {
      .gray {
        background-color: #f8f8f8;
      }

      tbody {
        tr {
          td {
            border-top: 1px solid #eaedee;
            border-bottom: 1px solid #eaedee;
            border-left: 0;
            border-right: 0;

            &:first-child {
              border-left: 1px solid #eaedee;
            }

            &:last-child {
              border-right: 1px solid #eaedee;
            }

            padding-left: 20px;
          }
        }
      }
    }
  }
}
