@import '../../_assets/css/variables';

.custom-modal-style {
  position: fixed;
  width: 20%;
  top: 10.8em;
  right: 5em;
}

.gray-modal {
  background-color: #f8f8f8;
  border-radius: 10px;
  border-color: transparent;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  position: relative;

  .close-modal {
    position: absolute;
    right: 15px;
    top: 15px;
  }
  .filter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #7be0d3;
    padding: 0.5em;
    border-radius: 5px;
    margin-top: -10px;
  }
  .filter-header-title{
    color: #2e3074;
  }

  .modal-filter-sort-content {
    .modal-filter-header {
      padding: 0.5em 0 10px 24px;
    }
    .title-modal {
      font-size: 20px;
      font-weight: bolder;
      color: $link-color;
    }
    .close-content {
      width: 32px;
      font-size: 32px;
      line-height: 0;
      color: $link-color;
      position: relative;
      top: -15px;
      right: -7px;
      cursor: pointer;
      user-select: none;
    }
  }
}

.btn-modal-filter {
  color: white !important;
  margin-top: 2em;
  background-color: #3e61df !important;
  border-color: transparent !important;
}

.filter-button {
  position: relative;
  bottom: 2px;
}

.modal-common-filters-header {
  margin-top: 20px;
  color: #2e3074;
}
.modal-common-filters-item{
  color: #313132 !important;
}
