.print-button {
  position: absolute;
  right: 30px;
  top: 35px;
  background: none;
  border: none;
}
@media print {
  .hidePrint {
    display: none;
  }
}
