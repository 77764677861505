.btn-filters {
  width: 110px !important;
  padding: 0px;
  margin-left: 0.5rem;
  line-height: 5px;
  font-size: 16px;
  height: 34px !important;
  border-radius: 12px;
  border: white;
  box-shadow: 1px 2px 2px 2px rgba(175, 173, 173, 0.29) !important;
}
.selected {
  color: white;
  background-color: #8bebe0 !important;
  border-color: #8bebe0;
}

.unselected {
  color: black;
  background-color: white;
  border: none;
}
.btn-filters:hover {
  background-color: #8bebe0;
  border: #8bebe0;
}
