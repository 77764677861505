.text-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  align-items: center;
  text-align: center;
}

// .watch-demo-container {
//   display: flex;
//   justify-content: center;
//   margin-top: 30px;
//   align-items: center;
//   text-align: center;
// }
.img-container {
  align-items: center;
  justify-content: center;
}
// .complii-logo-container {
// position: absolute;
// }