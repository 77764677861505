.profile-feature {
  // background-color: lightgray;
  max-width: 1600px !important;
  .profile-header {
    font-weight: 300;
    font-size: 32px;
    color: #2e3074;
    white-space: pre-wrap;
  }
  .tips-panel {
    height: fit-content;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 10px;
    .tips-icon {
      float: left;
      margin-top: 20px;
    }
    .tips-text {
      margin-left: 55px;
      font-size: 17px;
      .tips-button {
        background-color: #d5f5f1;
        font-size: 15px;
        color: #2e3074;
        border: none;
        border-radius: 10px;
        padding: 5px 10px;
        margin-top: 5px;
      }
    }
  }

  .confirm-button {
    width: 90%;
  }

  .category-title {
    font-size: 24px !important;
    color: #2e3074 !important;
    margin-bottom: 0;
  }
  .category-subtitle {
    font-size: 14px;
    color: #313132 !important;
    margin-bottom: 0;
  }
  .causes {
    background-color: #edf1ff;
    border-radius: 10px;
    flex-direction: column;
    flex-wrap: nowrap;
    display: flex;
    margin-left: 0;
    margin-right: 0;
    color: #313132;
    height: 100%;
    // overflow: hidden;
    // overflow-y: auto;
  }

  .category-panel {
    margin-top: 40px;
    .check-box-wrapper {
      width: 80%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      background-color: #edf1ff;
      border-radius: 10px;
      margin-top: 20px;
      padding: 10px 40px;
      .checkbox-item {
        width: 100%;
        display: flex;
        padding: 15px 0;
        .check-box {
          display: flex;
          min-height: 40px;
          align-items: center;
          margin: 0 6px;
          .check-box-input {
            position: relative;
            cursor: pointer;
            outline: 0;
            line-height: 1;
            vertical-align: middle;
          }
          .check-box-input input[type='checkbox']:checked ~ .customised-checkbox {
            background-color: white;
          }
          .customised-checkbox:after {
            content: '';
            position: absolute;
            display: none;
          }
          .check-box-input input[type='checkbox']:checked ~ .customised-checkbox:after {
            display: block;
          }
          .check-box-input .customised-checkbox:after {
            left: 13px;
            bottom: 9px;
            width: 8px;
            height: 16px;
            border: solid #7be0d3;
            border-width: 0 4px 4px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
          .customised-checkbox {
            position: absolute;
            top: 0;
            left: 0;
            height: 34px;
            width: 34px;
            border-radius: 2px;
            background-color: white;
          }
          .check-box-inner {
            display: inline-block;
            position: relative;
            border: 1px solid #d4d9da;
            border-radius: 2px;
            box-sizing: border-box;
            width: 37px;
            height: 37px;
            background-color: #fff;
            z-index: 1;
            transition: border-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46),
              background-color 0.25s cubic-bezier(0.71, -0.46, 0.29, 1.46);
          }
          input[type='checkbox'] {
            visibility: hidden;
          }
          .check-box-label {
            display: inline-block;
            padding-left: 48px;
            line-height: 19px;
            font-size: 14px;
            .options-content {
              white-space: pre-wrap;
              word-break: break-word;
              line-height: 20px;
              font-size: 18px;
            }
          }
        }
      }
    }
    // @media (min-width: 1300px) {
    //   margin-top: 42.5px;
    // }
  }
  .draggable-area-grid {
    display: grid;
    grid-template-columns:repeat(5, 1fr);
    column-gap: 10px;
  }
  .states-draggable-area-grid{
  grid-template-columns: repeat(3, 1fr);
  margin: auto;
  width: 70%;
  }
  .lifestyle-tips-panel-header {
    grid-row: span 2;
    // width: 100%;
    text-align: center;
  }

  .support {
    background-color: #f4fefa;
    border-radius: 10px;
    flex-direction: column;
    height: 100%;
  }

  .avoid {
    background-color: #fff7fa;
    border-radius: 10px;
    height: 100%;
  }

  .unsure {
    background-color: #faf7ff;
    border-radius: 10px;
    height: 100%;
  }
}

.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 1000;
  .showup-modal {
    margin-top: 105px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15) !important;
    background-color: white;
    box-sizing: border-box;
    padding: 20px 20px 0;
    animation: moveIn 0.5s;
  }
  .comment {
    color: #313132;
    font-size: 20px;
    padding: 0 45px;
  }
}

.profile-prioritise {
  // background-color: lightgray;
  max-width: 1450px !important;
  .profile-header {
    font-weight: 300;
    font-size: 32px;
    color: #2e3074;
    white-space: pre-wrap;
  }
  .tips-panel {
    height: fit-content;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px 15px;
    border-radius: 10px;
    width: 90%;
    .tips-icon {
      float: left;
      margin-top: 10px;
    }
    .tips-text {
      margin-left: 45px;
      font-size: 17px;
      .tips-button {
        background-color: #d5f5f1;
        font-size: 15px;
        color: #2e3074;
        border: none;
        border-radius: 10px;
        padding: 5px 10px;
        margin-top: 5px;
      }
    }
  }

  .confirm-button {
    width: 80%;
  }

  .category-title {
    font-size: 24px;
    color: #2e3074;
    margin-bottom: 0;
  }
  .category-subtitle {
    font-size: 14px;
    color: #313132 !important;
    margin-bottom: 0;
  }
  .causes {
    background-color: #edf1ff;
    border-radius: 10px;
    flex-direction: column;
    flex-wrap: nowrap;
    display: flex;
    margin-left: 0;
    margin-right: 0;
    color: #313132;
    height: 100%;
  }

  .category-panel {
    margin-top: 40px;
  }

  .support {
    background-color: #f4fefa;
    border-radius: 10px;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;
  }

  .avoid {
    background-color: #fff7fa;
    border-radius: 10px;
    height: 100%;
  }

  .unsure {
    background-color: #faf7ff;
    border-radius: 10px;
    height: 100%;
  }
}
