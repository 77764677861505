.exposure-table .col {
  white-space: nowrap;
  padding: 5px 0px;
  text-align: center;
}

.exposure-table .holding-col {
  padding: 0px;
}

.exposure-row,
.exposure-row > .flex-12,
.exposure-row > .flex-6,
.exposure-row > .flex-4,
.exposure-row > .flex-6.profile-selection > div,
.exposure-row > .flex-4.exposure-holdings > div,
.holding-table {
  display: flex;
  align-items: center;
  justify-content: center;
}

.exposure-row {
  box-shadow: 1px 2px 2px 2px rgba(175, 173, 173, 0.29);
  background: white;
  border-radius: 10px;
  padding: 5px 10px;
  margin-bottom: 10px;
}

.exposure-row.header {
  box-shadow: none;
}

.exposure-row.header > .col {
  color: #2e3074;
  font-weight: 500;
  font-size: 18px;
}
.exposure-row > .flex-12 {
  justify-content: flex-start;
}

.exposure-row > .flex-6.profile-selection > div {
  width: 103px !important;
  height: 37px !important;
  border-radius: 10px;
}

.exposure-row > .flex-4.exposure-holdings > div {
  width: 61px;
  height: 37px;
  border-radius: 10px;
  background-color: #eaedee;
  color: #2e3074;
}
.icon.exposure {
  width: 42px;
  height: 41px;
}
.flex-2 {
  flex: 2 1 0%;
}

.flex-6 {
  flex: 6 1 0%;
}

.flex-4 {
  flex: 4 1 0%;
}

.flex-8 {
  flex: 8 1 0%;
}

.flex-10 {
  flex: 10 1 0%;
}

.flex-12 {
  flex: 12 1 0%;
}

.flex-24 {
  flex: 24 1 0%;
}

.holding-name {
  text-align: start;
  padding-left: 15px;
}

.holding-table-top {
  box-shadow: -1px 0 2px rgba(175, 173, 173, 0.29),
    /* left shadow */ 1px 0 2px rgba(175, 173, 173, 0.29); /* right shadow */
  // 0 -2px 2px rgba(175, 173, 173, 0.29);   /* top shadow */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.holding-table-bottom {
  box-shadow: -1px 0 2px rgba(175, 173, 173, 0.29),
    /* left shadow */ 1px 0 2px rgba(175, 173, 173, 0.29),
    /* right shadow */ 0 2px 2px rgba(175, 173, 173, 0.29); /* bottom shadow */
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-bottom: 8px;
}

.holding-table:not(.holding-table-top):not(.holding-table-bottom) {
  box-shadow: -1px 0 2px rgba(175, 173, 173, 0.29), 1px 0 2px rgba(175, 173, 173, 0.29);
}

.holding-row .holiding-col .holding-table {
  padding: 0 !important;
}

.holding-table {
  height: 60px;
}

.holding-table .holding-name {
  text-align: start;
  margin-left: -10px;
  white-space: normal;
}

.holding-name-padding {
  margin-left: 10px;
  padding-left: 10px;
}

.exposure-details-popup .popover {
  border: none;
  box-shadow: none;
  max-width: 465px;
  border-radius: 10px;
  background-color: #eaedee;
  padding: 20px;
  // margin-bottom: 10px;
  // outline: red;
  // outline-width:15px;
  // outline-style: solid;
}

.exposure-details {
  font-size: 1.3rem;
}

.exposure-details-text {
  margin-bottom: 0.5rem;
  color: #3e61df;
}
