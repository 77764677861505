button.hide-button {
  position: absolute;
  width: 85px;
  height: 50px;
  opacity: 0;
}

.send-modal-text {
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.start-send {
  position: absolute;
  left: -100px;
  white-space: nowrap;
}
.portfolio-heading {
  font-size: 20px;
  color: #111111;
}
.tip-panel-text {
  margin-top: -70px;
  color: #111111;
}

.instrument-match {
  margin-top: -10px;
}

.dropdown-body {
  padding: 5px 5px 0px 5px;
  // border-top: 1px solid #e5e8ec;
  display: none;
}

.dropdown-body.open {
  display: block;
  background-color: white;
  margin-top: 17px;
  position: relative;
  // left: 12px;
  top: -28px;
  border-radius: 5px;
  box-shadow: 1px 2px 2px 2px rgba(175, 173, 173, 0.29) !important;
  width: 3.5em;
  padding-bottom: 7px;
  z-index: 1;
  width: fit-content;

  &.firstName,
  &.lastName {
    right: -1em;
  }
  &.profileStatus {
    left: -1em;
  }
  &.profileView {
    left: -0.5em;
  }
  &.profileAction {
    left: -1em;
  }
  &.symbol {
    left: 0.5em;
  }
  &.type {
    left: -0.5em;
  }
  &.match {
    width: 4.6em;
    left: -0.5em;
  }
}

.dropdown-item {
  padding: 5px 5px 0px 5px;
  text-align: center;
}

.dropdown-item:hover {
  cursor: pointer;
  background-color: #f8f9fa;
}

.dropdown-item.selected {
  background-color: #f8f9fa;
}
img.shortcut-image-right {
  position: relative;
  top: -2em;
  width: 2.3em;
}
img.open {
  transform: rotate(180deg);
}
.client-list-header {
  height: 60px;
  background-color: #f8f9fa;
  border-radius: 5px;
}

.client-list {
  height: 80%;
  margin: 6px 5px;
  padding: 13px;
  white-space: nowrap;
  &.details {
    border: white;
    box-shadow: 1px 2px 2px 2px rgba(175, 173, 173, 0.29) !important;
    background: white;
    border-radius: 5px;
    padding-left: 20px;
  }
  &.edit {
    display: flex;
    justify-content: center;
    flex: 1;
  }
}

.profile-status-details {
  display: flex;
}

.profile-status-icon {
  margin-left: auto;
  margin-right: 1em;
}

.client-profile-view {
  position: relative;
  left: 40%;
}
.client-edit {
  margin: auto;
}

.client-count-result {
  z-index: 1;
  min-width: 25px;
  min-height: 25px;
  font-size: 15px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.15);
  color: #51d4c3;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}
.shortcut-container {
  display: flex;

  &.list-0 {
    height: 78%;
  }
  &.list-1 {
    height: 65%;
  }
  &.list-2 {
    height: 60%;
  }
  &.list-3 {
    height: 58%;
  }
}

.client-list-shortcut {
  border: white;
  box-shadow: 1px 2px 2px 2px rgba(175, 173, 173, 0.29) !important;
  background: #f8f9fa;
  border-radius: 5px;
  align-items: flex-end;
  gap: 1rem;
  padding: 1rem;
  margin-top: auto;
  width: 100%;
}

.shortcut-item {
  width: calc(33.33% - 0.8rem);
  border: white;
  box-shadow: 1px 2px 2px 2px rgba(175, 173, 173, 0.29) !important;
  background: white;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 1.5fr 2fr 1fr;
  align-items: center;
  height: 8em;
  padding: 15px;
}

.shortcut-text {
  font-size: 1.2em !important;
  margin: auto !important;
  line-height: 1.3em !important;
  white-space: nowrap;
}

.shortcut-image {
  display: flex;
  justify-content: flex-end;
}

.shortcut-image-left {
  width: 5.1em;
  &.link {
    width: 4.6em;
  }
  &.info {
    width: 4.7em;
  }
}

a {
  margin: auto;
}

.empty-client-list-heading {
  display: inline-block;
  text-align: center;
  width: 100%;
  margin-top: 50px;
}
.empty-client-list-text {
  font-size: 25px;
  color: #2e3074;
}
.client-list-heading {
  width: 13.5em;
}
.client-heading-list {
  width: 12.5em;
}
.client-count {
  margin-left: auto;
  margin-right: 0;
  position: relative;
  top: 5px;
  &.list {
    top: 2px !important;
  }
}

.width-20 {
  width: 20%;
}

.width-15 {
  width: 15%;
}
.portfolio-grid {
  display: grid;
  grid-template-columns:  1fr 50px;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
}
