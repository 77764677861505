@import '../../../../_assets/css/variables';

.box-advice {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  padding: 1em;
  margin-top: 2em;
  margin-bottom: 2em;

  .row {
    .text-center {
      margin-bottom: 0;
      .btn {
        padding: 5px;
        @media (max-width: 575.98px) {
          padding: 20px;
        }
      }
      @media (max-width: 575.98px) {
        margin-bottom: 20px;
      }
    }
  }

  .outputs-subtitle {
    color: #727e81;
    margin: 1em 0;
    font-size: 15px;
    font-style: italic;
    display: block;
    font-weight: normal !important;
  }

  .text-body {
    font-size: 16px;
    color: #000;
  }

  .the-purpose {
    width: 459px;
    color: #313132;
    font-family: $default-font;
    font-size: 17px;
    line-height: 29px;
    display: inline-block;
    @media (max-width: 575.98px) {
      display: inline;
    }
  }

  .short-list {
    position: relative;
    &.show {
      ul {
        height: auto !important;
      }
    }
    ul {
      display: inline-block;
      overflow: hidden;
      height: 70px;
      margin: 0;
    }
    .read-more {
      user-select: none;
      display: block;
      cursor: pointer;
      color: $link-color;
    }
  }
  input[type='text'] {
    background-color: #f8f8f8;
  }

  .radio-custom .form-check-label {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    color: #000;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 24px;
      width: 24px;
      border: 1px solid #727e81;
      background-color: #fff;
      border-radius: 50%;
    }

    /* On mouse-over, add a grey background color */
    &:hover input ~ .checkmark {
      background-color: #ccc;
    }

    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    input:checked ~ .checkmark {
      background-color: #fff;
    }

    input:checked ~ .checkmark:after {
      display: block;
    }

    .checkmark:after {
      top: 5px;
      left: 5px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #727e81;
    }
  }

  .dasshed-box {
    border: 2px dashed #7be0d3;
    padding: 1em;
    background-color: rgba(123, 224, 211, 0.05);
    .form-check-label {
      color: $link-color;
      font-size: 12px;
    }
    .form-row {
      margin-bottom: 1em;
    }
  }
  hr {
    margin-left: -1em;
    margin-right: -1em;
  }
  small {
    font-size: 12px;
  }
}

.content-btns {
  .text-center {
    margin-bottom: 0;
    .btn {
      padding: 5px;
      @media (max-width: 575.98px) {
        padding: 20px;
      }
    }
    @media (max-width: 575.98px) {
      margin-bottom: 20px;
    }
  }
}

.outcomes-actions {
  color: #3e61df;
  position: absolute;
  right: -95px;
  @media (max-width: 575.98px) {
    position: relative;
    right: 0;
  }
}
