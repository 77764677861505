html,
body {
  background: $white;

  input,
  textarea {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05) !important;
  }
}

html,
body,
div,
p,
span,
*,
a,
input,
button,
small {
  font-family: $default-font;
  font-size: 16px;
  color: #111111;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

label,
input[type='text'],
input[type='email'] {
  color: $primary-accent-color;
}

form label {
  margin-bottom: 8px;
}

a {
  color: $link-color !important;
}

a:hover {
  color: $primary-accent-color-hover;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  color: $link-color !important;
}

button:focus {
  outline: 0;
}

.btn-blue {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;

  .btn {
    height: 56px;
    width: 100%;
    max-width: 286px;
    border-radius: 28px;
    font-size: 18px;
    line-height: 22px;
    color: #fff;
    text-align: center;
    background-color: #3e61df;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-color: transparent;
    .spinner-border {
      color: #fff !important;
      &.text-primary {
        color: #fff !important;
      }
    }
    &:hover {
      background: $button-hover;
      border-color: $button-hover;
    }
  }
}

.btn {
  .spinner-border {
    color: #fff !important;
    &.text-primary {
      color: #fff !important;
    }
  }
}

.btn-blue-disabled {
  .btn {
    height: 56px;
    width: 100%;
    max-width: 286px;
    border-radius: 28px;
    font-size: 18px;
    line-height: 22px;
    color: #fff;
    text-align: center;
    background-color: #3e61df;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-color: transparent;

    &:hover {
      background: $button-hover;
      border-color: $button-hover;
    }
  }
}

#root {
  min-height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $title-font-family;
  color: $header-font-color;
  font-weight: 500 !important;
}

.container {
  max-width: 550px;
}

.full-height {
  min-height: 100vh;
}

.margin-v-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.margin-v-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.margin-v-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.margin-v-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.login-left-side {
  padding: 12px 97px 0 0;

  @media (max-width: 768px) {
    text-align: left;
    max-width: 100%;
    float: none;
  }
  @media (max-width: 575.98px) {
    padding: 15px;
  }
  input {
    color: #313132 !important;
  }
}

label {
  font-size: 15px;
  line-height: 18px;
}

input[type='text'],
input[type='password'],
input[type='email'],
input[type='number'],
input[type='phone'] {
  height: 57px;
  color: #495057;
  font-size: 23px;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
  border-radius: 5px;
  border: none;
  box-sizing: border-box;
  padding: 10px;
  @include placeholder {
    color: #b6c3c6;
  }
}

.css-1uccc91-singleValue {
  font-size: 23px;
  padding-top: 0;
  font-weight: 300;
  top: 26px;
}

.css-w8afj7-Input,
.css-1g6gooi {
  input {
    top: -10px !important;
    position: absolute;
    font-size: 23px !important;
    width: 100% !important;
    padding: 0px !important;
  }
}

.css-1gtu0rj-indicatorContainer,
.css-1wy0on6 > [class*='indicatorContainer'],
.css-1wy0on6 > [aria-hidden] {
  display: none !important;
}

.step-progress {
  .css-1gtu0rj-indicatorContainer,
  .css-1wy0on6 > [class*='indicatorContainer'],
  .css-1wy0on6 > [aria-hidden] {
    display: block !important;
  }
}

.wizard-top-title {
  margin-bottom: 53px;
  .sign-up-title {
    color: #7be0d3;
    font-weight: 500;
    font-size: 45px;
    line-height: 44px;
    text-align: center;
    letter-spacing: -0.57px;
    margin-top: 40px;
    font-family: $title-font-family;
  }
  .list-group-item {
    padding: 13px;
  }
}

.don-t-have-an-accoun {
  color: #ffffff !important;
  font-size: 17px;
  line-height: 29px;
}

.content-invite-code {
  max-width: 502px;
  margin: 0 auto;
  margin-bottom: 85px;

  .ml-15 {
    margin-left: -15px;
  }
}

.login-image {
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(../../src/_assets/img/icons/graphics/intro@3x.png);
  height: 607px;
  width: 506px;
  position: absolute;
  right: 0;
  top: 45px;
}

.gradient-login {
  // background-color: $gradient-login;
  //background-color: linear-gradient(180deg, $gradient-login-1 0%, $gradient-login-2 100%);
  height: 100%;
  width: 100%;
  //max-width: 415px;
  // background: linear-gradient(180deg, #3e61df 0%, #36d1dc 100%);
  @media (max-width: 768px) {
    float: left;
    display: block;
    width: 100%;
    height: 200px;
  }
}
.sign-in-btn {
  background-color: #7be0d3 !important;
  color: #000000 !important;
}
.sign-in-link {
  color: #7be0d3 !important;
}
.sign-up-link {
  color: #7be0d3 !important;
}
.forgot-password {
  color: #7be0d3 !important;
  font-size: 18px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: left;
}

.centered {
  margin: 0 auto;
}

.clear:after {
  clear: both;
  display: table;
  content: '';
}

.window-in-two {
  width: 100%;
  max-width: 1024px;
}

.desktop-only {
  visibility: visible;
  opacity: 1;
  @media (max-width: 768px) {
    visiblity: hidden;
    opacity: 0.01;
    height: 0px;
    width: 0px;
    display: none !important;
  }
}

.mobile-only {
  opacity: 0.01;
  height: 0px;
  width: 0px;
  display: none !important;
  @media (max-width: 768px) {
    visibility: visible;
    height: auto;
    width: auto;
    opacity: 1;
  }
}

a:hover {
  text-decoration: none;
}

ul {
  padding-inline-start: 0;
}

label {
  margin-bottom: 0.1rem;
}

.mb-150 {
  margin-bottom: 150px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mt-150 {
  margin-top: 150px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-5 {
  margin-top: 5px !important;
}

.ml-40 {
  margin-left: 40px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-auto {
  margin-left: auto;
}

.mr-40 {
  margin-right: 40px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-0 {
  margin-right: 0px;
}

.ml-1 {
  margin-left: 1px;
}

.mr-1 {
  margin-right: 1px;
}

.padding-10 {
  padding: 10px;
}

.padding-15 {
  padding: 15px;
}

.padding-20 {
  padding: 20px;
}

.padding-30 {
  padding: 30px;
}

.pl-0 {
  padding-left: 0px;
}

.pr-0 {
  padding-right: 0px;
}

.pt-0 {
  padding-top: 0px;
}

.pb-0 {
  padding-bottom: 0px;
}

.pd-right-90 {
  padding-right: 90px;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-1 {
  opacity: 1 !important;
}

.pointer-events-none {
  pointer-events: none !important;
}

.force-invalid {
  display: block;
}

.sign-out-content {
  .btn {
    margin-bottom: 0.5rem;
    margin-top: 5px;
  }
}
.signin-form {
  label {
    color: #fff;
  }
}
// Firefox hack shadow red required
textarea:required,
input:required {
  box-shadow: none;
}

.z-index-front {
  z-index: 99999;
}

.form-control {
  height: 50px;
  background-color: $white;
  border-color: $white;
}

.input-toggle {
  top: 45% !important;
  background-color: inherit !important;
  margin-top: -15px !important;
}

.eye-password {
  width: 29px;
  height: 17px;
  background-color: inherit;
}

.pd-button {
  padding: 14px 50px 14px 50px !important;
  max-width: 437px;
  font-size: 22px;
  font-weight: 300;
  line-height: 27px;
  color: #2e3074;
  height: 101px;
}

.pd-button-big {
  padding: 14px 90px 14px 90px;
}

.select-practice-button {
  padding: 45px 0px 45px 0px;
  background-color: $dark-grey;
  border-radius: $border-radius;
  cursor: pointer;
  font-size: 20px;
}

.inside-input-button {
  position: absolute;
  right: 8px;
  top: 8px;
  border: none;
  height: 20px;
  width: auto;
  border-radius: 100%;
  outline: none;
  text-align: center;
  font-weight: bold;
  padding: 2px;
  .btn {
    width: 170px;
    height: 38px;
    font-size: 16px !important;
    border-radius: 19px;
    border-color: transparent;
    color: #2e3074;
    line-height: 19px;
    letter-spacing: -0.35px;
  }
}

.inside-input-button button {
  padding-left: 45px;
  padding-right: 45px;
  z-index: 9999;
}

.invalid-input div input {
  border-color: $input-error !important;
  // background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc354…%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.underline {
  text-decoration: underline;
}

.no-underline {
  text-decoration: none !important;
}

.z-index-1 {
  z-index: 1;
}

.container {
  max-width: 750px;
}

.account-invite-code {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px dashed #878787;
  padding: 20px;
  @media (max-width: 575.98px) {
    justify-content: center;
  }
  span {
    display: flex;
    font-size: 25px;
    font-weight: bold;
    color: #2e3074;
    @media (max-width: 575.98px) {
      margin-bottom: 10px;
    }
  }
  .btn {
    max-width: 200px;
    height: 44px;
    border-radius: 19px !important;
    font-size: 16px !important;
    padding: 0;
    background-color: #a4e6df !important;
    border-color: #a4e6df !important;
  }
}

.btn:not(.pd-button) {
  max-height: 58px;
  max-width: 286px;
  font-size: 18px;
  @media (max-width: 575.98px) {
    max-width: 100% !important;
  }
  &.btn-link {
    font-size: 17px;
    padding: 0;
    color: #3e61df;
    max-width: 100% !important;
  }
  .btn-main-shadowless {
    background-color: #a4e6df !important;
    border-color: transparent;
    width: 100%;
    color: #fff !important;
    padding: 15px;
    border-radius: 28px;
    &:hover {
      background-color: $button-hover !important;
    }
    &.lightblue-btn {
      padding: 5px;
      background-color: rgba(123, 224, 211, 0.2);
      color: #2e3074;
      border-radius: 19px;
      border-color: transparent;
      font-size: 13px;
      &.text {
        font-size: 13px;
      }
      &:hover {
        background-color: $button-hover !important;
      }
    }
    &.blue-btn{
      color: white !important;
      background-color: #3e61df !important;
      &:hover {
        background-color: #071d6b !important;
      }
    }
  }
  &:hover {
    border-color: transparent;
  }
}

.blue-btn {
  max-height: 58px;
  max-width: 286px;
  font-size: 18px;
  @media (max-width: 575.98px) {
    max-width: 100% !important;
  }

    background-color: #a4e6df !important;
    border-color: transparent;
    width: 100%;
    color: #fff !important;
    padding: 15px;
    border-radius: 28px;
    &:hover {
      background-color: $button-hover !important;
    }
    
      color: white !important;
      background-color: #3e61df !important;
      &:hover {
        background-color: #071d6b !important;
      }
    }

.text-muted {
  color: #313132 !important;
}

.client-list {
  a {
    color: #313132 !important;
    &.start-link {
      color: #3e61df !important;
    }
  }
}

.list-group-item {
  margin: 6.5px 0;
  border-radius: 8px;
  text-align: left;
  display: flex;
  height: 65px;
  align-items: center;
  border-color: transparent;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
  &.big-list-item {
    height: 104px;
    margin: 7.5px 0;
    .list-item-img {
      img {
        width: auto;
        max-width: initial;
        height: 65px;
      }
    }
    .list-item-info {
      align-self: baseline;
    }
  }
  .start-link {
    color: $link-color;
    font-size: 15px;
  }
  p {
    margin: 0;
  }
}

.modal-filter-sort-content {
  .list-group-item {
    height: auto !important;
  }
}

.copy-paste-fields {
  font-size: 14px;
  .light-gray {
    color: #b6c3c6;
  }
}

.step-copy-paste {
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  label {
    color: #313132 !important;
  }
  h2 {
    margin-right: 10px;
  }
}

.modal-content {
  border-radius: 10px !important;
}

.sort-modal-content-input {
  input[type='text'] {
    background-color: #f8f8f8;
  }
  .radio-custom {
    .form-check {
      margin-bottom: 1em;
    }
    .form-check-label {
      display: block;
      position: relative;
      padding-left: 35px;
      cursor: pointer;
      color: #000;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }

      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 24px;
        width: 24px;
        border: 1px solid #727e81;
        background-color: #fff;
        border-radius: 50%;
      }

      /* On mouse-over, add a grey background color */
      &:hover input ~ .checkmark {
        background-color: #ccc;
      }

      .checkmark:after {
        content: '';
        position: absolute;
        display: none;
      }

      input:checked ~ .checkmark {
        background-color: #fff;
      }

      input:checked ~ .checkmark:after {
        display: block;
      }

      .checkmark:after {
        top: 5px;
        left: 5px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #727e81;
      }
    }
  }
}

.client-list {
  .list-group-item,
  .list-group-item:last-child,
  .list-group-item:first-child {
    width: 100%;
    height: 64px;
    margin: 5px auto;
  }
}

.modal-body {
  .x-modal-filter {
    position: absolute !important;
    top: 21px !important;
    right: 22px !important;
  }
  .list-group-item {
    height: 65px;
  }
  .list-group-item:first-child {
    border-radius: 10px;
    width: 100%;
    height: inherit;
    padding: 17px;
    margin: 10px auto;
  }

  .list-group-item:last-child {
    border-radius: 10px;
    width: 100%;
    height: inherit;
    padding: 17px;
    margin: 10px auto;
  }
}

.form-control.is-valid {
  border-color: transparent;
}

.status-pill {
  margin-bottom: 0;
  padding-top: 0.1rem !important;
  padding-bottom: 0.1rem;
  border: none !important;
  min-width: 84px;
  max-width: 84px;
  padding-left: 0;
  padding-right: 0;
  text-align: center;
  color: white;
  // line-height: 28px !important;

  &.match {
    background-color: #7dd489;
    border-color: #7dd489;
  }
  &.conflict {
    background-color: #bd5f80;
    border-color: #bd5f80;
  }
  &.no-match {
    background-color: #cdb3f1;
    border-color: #cdb3f1;
  }
  &.partial-match {
    background-color: #ffb387;
    border-color: #ffb387;
  }
}

.vertical-align {
  width: 50%;
  height: 30%;
  margin: auto;
}

.font-12 {
  font-size: 12px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-22 {
  font-size: 22px;
}

.letter-spacing {
  letter-spacing: -0.39px;
}
.donut-row {
  position: relative;
  top: -30px;
  left: -25px;
  .donut-container {
    width: 100%;
    height: 100%;
    position: relative;
    justify-content: center;
    align-items: center;
    &.circle {
      width: 188px;
      height: 188px;
    }
    &.small-circle {
      width: 125px;
      height: 138px;
    }
    &.line-chart {
      height: 432px;
      margin-bottom: 2em;
      canvas {
        border: 1px solid #eaedee;
        border-top: none;
        border-right: none;
      }
    }
  }
}
.register-page-tagline{
  color: #FFFFFF;
  font-size: 27px;
  line-height: 42px;
  text-align: center;
}
.register-agree {
  padding: 0;
  @media (max-width: 575.98px) {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
  }
}

.donut-text-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > h5 {
    color: #313132;
    font-size: 23px;
    font-family: $default-font;
    font-weight: 300;
    line-height: 28px;
    padding: 0;
    margin: 0;
  }

  > p {
    font-size: 12px;
    margin-bottom: 0;
  }
}

.company-logo {
  height: 100px;
  width: 100px;
}

.company-icon {
  background: white;
  border-radius: 5px;
  padding: 5px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.price-history {
  color: #2e3074;
  font-size: 14px;
}

.table-vertical {
  > tbody > tr > th {
    background-color: #d6d8d9;
    font-size: 16px;
    font-weight: normal;
  }
}

.text-darkblue {
  color: #2e3074 !important;
}

.text-grey {
  color: #656577 !important;
}

.title-pages {
  color: #2e3074;
  font-size: 32px;
  font-weight: 500 !important;
  letter-spacing: -0.57px;
  line-height: 40px;
  font-family: $title-font-family;
}

.padding-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.btn-main {
  background-color: #3e61df;
  border-color: #3e61df;
  width: 100%;
  padding: 15px;
  border-radius: 28px;
  -webkit-box-shadow: 0 0 5px 2px rgba(199, 199, 199, 0.72);
  -moz-box-shadow: 0 0 5px 2px rgba(199, 199, 199, 0.72);
  box-shadow: 0 0 5px 2px rgba(199, 199, 199, 0.72);
  letter-spacing: 1px;
}

.btn-secondary {
  background-color: transparent;
  border-color: $icon-active;
  border-width: 2px;
  width: 100%;
  color: #313132;
  padding: 15px;
  height: 56px;
  border-radius: 28px;
  &:hover {
    border-color: $button-hover;
    background-color: $button-hover;
    color: #fff;
  }
  &.disabled {
    background-color: #a4e6df;
    border-color: transparent;
    color: #313132;
    &:hover {
      background-color: #a4e6df;
    }
  }
}
.client-upload-container {
  padding: 0;
}

.btn-main-shadowless {
  background-color: #a4e6df;
  border-color: transparent;
  width: 100%;
  color: #000000;
  padding: 15px;
  border-radius: 28px;
  letter-spacing: 1px;
  &.lightblue-btn {
    padding: 5px;
    background-color: rgba(123, 224, 211, 0.2);
    color: #2e3074;
    border-radius: 19px;
    border-color: transparent;
    font-size: 13px;
    &.output-advice-btn {
      background-color: transparent;
      border-color: transparent;
      color: #656577;
      border-radius: 28px;
      height: 32px;
      max-width: 147px !important;
      &.active {
        border-color: #e5f9f6;
        background-color: #e5f9f6;
        color: #656577;
      }
    }
  }
}

.spinner-border {
  width: 24px;
  height: 24px;
}

.hide-flag {
  opacity: 0;
  pointer-events: none;
}

.btn-modal {
  font-size: 14px;
  background-color: #caf8f2;
  border-radius: 28px;
  width: 100%;
  border: 0;
  color: #2e3074;
}
.btn-cta {
  background-color: #a4e6df;
  color: #000000;
}

.cursor-hand {
  cursor: pointer;
}

.btn-submit-secondary {
  background-color: #3e61df;
  border-color: #3e61df;
  font-size: 14px;
  width: 100%;
  padding: 8px;
  border-radius: 28px;
  -webkit-box-shadow: 0 0 5px 2px rgba(199, 199, 199, 0.72);
  -moz-box-shadow: 0 0 5px 2px rgba(199, 199, 199, 0.72);
  box-shadow: 0 0 5px 2px rgba(199, 199, 199, 0.72);
  letter-spacing: 1px;
}

.clarification {
  color: #727e81;
  font-size: 15px;
  font-style: italic;
  display: block;
  margin-top: 7px;
}

.text-comment {
  font-style: oblique;
  color: #878787;
}

.sidebar-back-link {
  font-family: $default-font;
  font-size: 16px;
  padding-left: 20px;
  @media (max-width: 768px) {
    margin-top: 0;
    margin-top: 0;
    padding-left: 10px;
  }
}

.sidebar-client {
  font-size: 22px;
  font-weight: bolder;
  font-family: $title-font-family;
  color: #2e3074;
  padding-left: 20px;
  @media (max-width: 768px) {
    margin-top: 15px;
    padding-left: 10px;
    font-size: 16px;
    margin-bottom: 10px;
  }
}

.text-white {
  color: white;
}

.featured-text {
  background-color: #2e3074;
  color: #fff;
  padding: 2px 10px;
  border-radius: 5px;
  font-size: 14px;
}

.comments-card {
  padding: 7px;
  border-radius: 10px;
  background-color: #fff;
  -webkit-box-shadow: 0 0 4px 1px rgba(241, 241, 241, 0.6);
  -moz-box-shadow: 0 0 4px 1px rgba(241, 241, 241, 0.6);
  box-shadow: 0 0 4px 1px rgba(241, 241, 241, 0.6);
}

.btn-white {
  display: flex;
  align-items: center;
  padding: 5px 0;

  button {
    background-color: transparent !important;
    border: 0 !important;
  }

  border: 0;
  background-color: transparent;

  &:hover {
    background-color: transparent;
    color: lightblue;
  }

  &:active,
  &.active,
  &:focus {
    background-color: transparent;
    color: lightblue;
    outline: none;
    box-shadow: none;
  }

  &.active + .sidebar-list {
    max-height: inherit;
  }

  &:checked {
    background-color: transparent;
    color: lightblue;
  }
}

.dropzone {
  background-color: #fff;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 0;
  height: 57px;
}

.no-padding {
  padding: 0;
}

.upload-title {
  color: #313132;
  font-family: $default-font;
  font-size: 17px;
  line-height: 29px;
  margin-bottom: 10px;
  display: block;
}

.browser-button {
  background-color: #8bebe0;
  border-color: #8bebe0;
  font-size: 14px;
  padding: 8px;
}

.upload-card {
  background-color: #d5f5f1;
  padding-bottom: 10px;
  padding-top: 20px;
  border-radius: 10px;
  @media (max-width: 575.98px) {
    div {
      padding: 10px;
      text-align: center;
    }
  }
  h5 {
    font-size: 22px;
    font-weight: 300 !important;
  }
  p {
    font-size: 14px;
  }
}

.row-card {
  background-color: #fff;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 4px 1px rgba(241, 241, 241, 0.6);
  -moz-box-shadow: 0 0 4px 1px rgba(241, 241, 241, 0.6);
  box-shadow: 0 0 4px 1px rgba(241, 241, 241, 0.6);
}

.data-grid-container {
  .data-grid {
    .cell > input {
      width: 100% !important;
      height: 100% !important;
    }
    @media (max-width: 768px) {
      display: block;
      overflow-x: auto;
      white-space: nowrap;
      tbody {
        tr {
          td {
            white-space: nowrap !important;
            padding: 0 1em !important;
          }
        }
      }
    }
  }
}
.btn-outline-secondary {
  background-color: transparent;
}

.font-weight-bold {
  font-weight: 500 !important;
}

// custom select
.css-yk16xz-control,
.css-lpahdxg-control,
.css-2b097c-container,
.css-1pahdxg-control {
  height: 57px;
  width: 502px;
  border-color: transparent !important;
  padding: 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05);
}

.alert {
  z-index: 2;
}

.css-1pahdxg-control {
  .css-1uccc91-singleValue {
    opacity: 1;
  }
}

.css-1hwfws3 {
  height: 36px;
  padding: 2px 12px;
}

.css-1wa3eu0-placeholder {
  font-size: 23px;
  color: #b6c3c6 !important;
  font-weight: 300;
  letter-spacing: -0.5px;
  line-height: 28px;
}

.css-1g48xl4-IndicatorsContainer,
.css-1wy0on6 {
  position: relative;
  height: 36px;
  overflow: hidden;
  span,
  div {
    opacity: 0;
  }
  &:after {
    content: '';
    position: absolute;
    width: 36px;
    height: 36px;
    top: 10px;
    left: -8px;
    background-color: #fff;
    border-radius: 6px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 16px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiIgdmlld0JveD0iMCAwIDI5Mi4zNjIgMjkyLjM2MiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjkyLjM2MiAyOTIuMzYyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgY2xhc3M9ImhvdmVyZWQtcGF0aHMiPjxnPjxnPgoJPHBhdGggZD0iTTI4Ni45MzUsNjkuMzc3Yy0zLjYxNC0zLjYxNy03Ljg5OC01LjQyNC0xMi44NDgtNS40MjRIMTguMjc0Yy00Ljk1MiwwLTkuMjMzLDEuODA3LTEyLjg1LDUuNDI0ICAgQzEuODA3LDcyLjk5OCwwLDc3LjI3OSwwLDgyLjIyOGMwLDQuOTQ4LDEuODA3LDkuMjI5LDUuNDI0LDEyLjg0N2wxMjcuOTA3LDEyNy45MDdjMy42MjEsMy42MTcsNy45MDIsNS40MjgsMTIuODUsNS40MjggICBzOS4yMzMtMS44MTEsMTIuODQ3LTUuNDI4TDI4Ni45MzUsOTUuMDc0YzMuNjEzLTMuNjE3LDUuNDI3LTcuODk4LDUuNDI3LTEyLjg0N0MyOTIuMzYyLDc3LjI3OSwyOTAuNTQ4LDcyLjk5OCwyODYuOTM1LDY5LjM3N3oiIGRhdGEtb3JpZ2luYWw9IiMwMDAwMDAiIGNsYXNzPSJob3ZlcmVkLXBhdGggYWN0aXZlLXBhdGgiIHN0eWxlPSJmaWxsOiMyRTMwNzQiIGRhdGEtb2xkX2NvbG9yPSIjMDAwMDAwIj48L3BhdGg+CjwvZz48L2c+IDwvc3ZnPg==);
  }
}

.research-col {
  .css-tlfecz-indicatorContainer,
  .css-1g48xl4-IndicatorsContainer {
    display: none;
  }
  .css-1uccc91-singleValue {
    color: #b6c3c6;
    letter-spacing: -0.5px;
  }
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #f8f8f8;
}

.slim-content {
  width: 100%;
  max-width: 690px;
  margin: 0 auto;
  .types-btn {
    line-height: 5px;
    font-size: 16px;
    height: 34px;
    border-radius: 12px;
    border: 0.5px solid #8bebe0;
  }

  .selected {
    background-color: #8bebe0 !important;
    border-color: #8bebe0;
    box-shadow: none;
  }
  .unselected {
    color: $header_font-color;
    background-color: white;
    border-color: $button-hover;
    box-shadow: none;
    &:hover {
      border-color: $button-hover !important;
      background-color: #8bebe0;
    }
  }
}

.btn-profile-detail,
.small-btn {
  height: 38px;
  width: 170px;
  padding: 5px;
  font-size: 16px !important;
  &.search-filter-sort-btn {
    font-size: 15px !important;
    height: 32px;
    width: 190px;
    color: #2e3074;
    background-color: #d5f5f1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.shadow {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.05) !important;
}

.form-control {
  font-size: 23px;
}

.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-position: center right 11.625px;
}

// .form-control:invalid,
// .form-control.is-invalid {
//   border: 2px solid #dc3545;
// }

.modal-dialog {
  @media (min-width: 576px) {
    max-width: 672px;
    .cursor-hand {
      position: relative;
      top: -20px;
      right: -10px;
    }
  }
}

.result-text {
  font-style: italic;
  color: #727e81;
  font-size: 15px;
}

// Toast error styles
.Toastify__toast {
  &.Toastify__toast--error {
    .Toastify__toast-body {
      color: #fff;
    }
  }
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f8f8f8;
}

.content-btns {
  .btn-main-shadowless.lightblue-btn {
    background-color: #a4e6df !important;
    color: #000000 !important;
    font-size: 16px;
    letter-spacing: -0.35px;
    line-height: 19px;
    height: 38px;
    width: 147px;
    &.disabled {
      background-color: #d0dedc !important;
      color: #656577 !important;
    }
  }
}

.table-bordered th,
.table-bordered td,
.table-bordered {
  border: 1px solid #eaedee;
}

.table-rounded table tbody tr td {
  border-top: 0;
}

.invalid-select {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  border-radius: 3px;
}

.price-history-date {
  text-transform: uppercase;
  font-size: 11px;
  color: #2e3074;
  font-family: $default-font;
}

.reset-filter {
  color: #3e61df;
  font-size: 16px;
  line-height: 29px;
  border: none;
  background: none;
  padding: 0;
  margin-left: auto;
  margin-top: auto;
  &.portfolio-screen {
    font-size: 17px;
    position: relative;
    right: -29.5em;
  }
}

.advice-output-btn {
  height: 36px;
  width: 150px;
  color: #2e3074;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
}

.white-color {
  color: white !important;
}

.blue-font-color {
  color: $primary-accent-color !important;
}

.custom-table {
  display: inline-table;
  table-layout: fixed;
  thead,
  tbody {
    border-bottom: 1px solid #f9f9f9;
  }
  th {
    font-size: 15px;
    font-weight: normal;
    color: $primary-accent-color;
  }
  td {
    font-size: 14px;
    cursor: pointer;
    border-bottom: 1px solid #f9f9f9;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
  }
  th:nth-child(2),
  td:nth-child(2) {
    text-align: left;
  }

  .green-text {
    color: #2ab564;
  }
  .score {
    font-size: 14px;
    background: #eaedee;
    border-radius: 10px;
    height: 32px;
    color: #2e3074;
  }
}

.custom-table-compare {
  width: 10%;
  display: flex;
  float: right;
  margin: 0;
  table-layout: fixed;
  thead,
  tbody {
    border-bottom: 1px solid #f9f9f9;
  }
  th {
    font-size: 15px;
    font-weight: normal;
    color: $primary-accent-color;
  }
  td {
    font-size: 14px;
    cursor: pointer;
    border-bottom: 1px solid #f9f9f9;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
  }
  th:nth-child(2),
  td:nth-child(2) {
    text-align: left;
  }

  .green-text {
    color: #2ab564;
  }
}

.panel {
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15) !important;
  padding: 30px;
  background-color: white;
}
// Edit Css styles for options on the top of the page
.insights-options-adviser {
  font-size: 14px;
  font-family: Helvetica;
  font-weight: 300;
  color: #adadad;
  padding-right: 6px;
  border: none;
  background-color: transparent;
  &.active {
    color: #3e61df;
  }
}
.insights-options-ethicAdviser {
  @extend .insights-options-adviser;
  padding-left: 6px;
}

// Edit css styles for InsightsBarchartGraph
.barchart-panel {
  border-radius: 10px;
  box-shadow: 0 0 4px #d5d4d4 !important;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
  height: 480px;
  canvas.chartjs-render-monitor {
    max-height: 390px !important;
  }
  .barchart-title {
    color: #363b4e !important;
    font-size: 18px;
    margin-bottom: 15px;
  }
  .barchart-subtitle {
    display: inline-block;
    position: absolute;
    top: 17px;
    right: 100px;
    .barchart-subtitle-year {
      font-size: 12px;
      font-family: Helvetica;
      font-weight: 300;
      color: #adadad;
      padding-right: 6px;
      border: none;
      background-color: transparent;
    }
    .barchart-subtitle-symbol {
      width: 2px;
      height: 8px;
      color: #979797;
    }
    .barchart-subtitle-lifetime {
      @extend .barchart-subtitle-year;
      color: #3e61df;
      padding-left: 6px;
    }
  }
  .barchart-buttons {
    display: inline-block;
    position: absolute;
    top: 17px;
    right: 35px;
    .barchart-buttons-right {
      border-radius: 3px;
      width: 20px;
      height: 25px;
      border: none;
      color: #fff;
      background-color: #a7d9c5;
      font-size: 26px;
      line-height: 20px;
      &:hover {
        background-color: #afe7d1;
      }
      &.disabled {
        background-color: #b6c3c6;
        cursor: not-allowed;
      }
    }
    .barchart-buttons-left {
      @extend .barchart-buttons-right;
      margin-right: 5px;
      &.disabled {
        @extend .barchart-buttons-right, .disabled;
      }
    }
  }
}
// Edit css styles for InsightsDoughnutGraph
.doughnut-Panel {
  border-radius: 10px;
  box-shadow: 0 0 4px #d5d4d4 !important;
  box-sizing: border-box;
  width: 520px;
  height: 480px;
  position: relative;
  .doughnut-title {
    font-size: 18px;
    font-family: Helvetica;
    font-weight: 300;
    color: #363b4e;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .doughnut-subtitle {
    display: inline-block;
    position: absolute;
    top: 20px;
    right: 60px;
    .doughnut-subtitle-year {
      font-size: 12px;
      font-family: Helvetica;
      font-weight: 300;
      color: #adadad;
      padding-right: 6px;
      border: none;
      background-color: transparent;
    }
    .doughnut-subtitle-symbol {
      width: 2px;
      height: 8px;
      color: #979797;
    }
    .doughnut-subtitle-lifetime {
      @extend .doughnut-subtitle-year;
      color: #3e61df;
      padding-left: 5px;
    }
  }
  .doughnut-footer {
    .doughnut-footer-left {
      width: 220px;
      height: 30px;
      border-radius: 2px;
      background-color: #8de1eb;
      padding: 7px 5px 5px;
      left: 20px;
      bottom: 40px;
      position: absolute;
      font-size: 12px;
      color: #fff;
      box-sizing: border-box;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .doughnut-footer-right {
      @extend .doughnut-footer-left;
      background-color: #3e61df;
      right: 20px;
      left: unset;
    }
    .doughnut-footer-text {
      font-size: 12px;
      color: #fff;
    }
  }
  .apexcharts-legend {
    width: 230px;
  }
  .apexcharts-pie-label {
    font-size: 12px;
  }
}
// Edit css styles for InsightsTable
.table-panel {
  border-radius: 10px;
  box-shadow: 0 0 4px #d5d4d4 !important;
  padding: 10px;
  box-sizing: border-box;
  width: 104%;
  .table-title {
    color: #363b4e;
    font-size: 18px;
  }
  .table-options {
    display: inline-block;
    position: absolute;
    top: 20px;
    right: 50px;
    .table-options-profiles {
      font-size: 12px;
      font-family: Helvetica;
      font-weight: 300;
      color: #adadad;
      padding-right: 6px;
      border: none;
      background-color: transparent;
      &.active {
        color: #3e61df;
      }
    }
    .table-options-symbol {
      width: 2px;
      height: 8px;
      color: #979797;
    }
    .table-options-portfolios {
      @extend .table-options-profiles;
      padding-left: 5px;
      padding-right: 0;
    }
  }
  th {
    font-size: 15px;
    color: #2e3074;
  }
  tbody td {
    font-size: 15px;
    font-weight: 900;
    color: #313132;
    vertical-align: middle;
  }
  .table-data-return {
    color: #2ab564;
    letter-spacing: 1px;
  }
}

.advice-panels-group {
  background-color: #f7f7f7;
  margin: 10px auto;
  border-radius: 5px;
  padding: 10px 15px 10px 0;
  max-width: 37%;
}
// Edit styles for advice activity panel
.advice-activity-title {
  font-size: 18px;
  color: #363b4e;
  font-weight: 700;
  margin-bottom: 20px;
}
.advice-activity-panel {
  background-color: #fff;
  @extend .table-panel;
  padding: 50px 30px 20px;
  .apexcharts-text {
    font-size: 35px;
    &:first-child {
      display: none;
    }
  }
  .apexcharts-datalabel-value {
    transform: translateY(-10px);
  }
  .advice-activity-note {
    margin: 15px auto;
    font-weight: 300;
    font-size: 14px;
  }
}
// Edit styles for Average Action and MatchesConflicts
.advice-panel {
  background-color: #fff;
  @extend .table-panel;
  padding: 15px;
  .advice-panel-note {
    margin: 15px auto;
    font-weight: 300;
    font-size: 10px;
  }
  .dash-line {
    top: 20px;
    height: 75%;
    border-right: 2px dashed #d9d6d6;
    position: absolute;
    left: 51%;
  }
}
.text-link-color {
  color: $link-color;
}

.doughnut-wrapper {
  position: relative;
  width: 100%;
  margin-top: 20px;
}
.review-apl-button {
  color: #2e3074 !important;
}
.review-portfolio-button {
  color: #2e3074 !important;
}

.sign-in-lottie {
  height: 300px !important;
  width: 500px !important;
  margin-top: 240px;
  margin-bottom: 90px;
  right: 40px;
  scale: 1.6;
  position: relative;
}
.no-margin {
  margin: 0!important;
}
