@import '../../scss/variables';

.sidebar {
  width: 30%;
  min-width: 328px;
  min-height: 100vh;
  padding: 30px 10px 20px;
  background: $sidebar-background;
  position: relative;
  z-index: 2;
  transition: all 0.25s ease-out;
  -webkit-transition: all 0.25s ease-out;

  @media (min-width: 1440px) {
    width: 20%;
  }
  @media (max-width: 768px) {
    position: relative;
    height: auto;
    width: 240px;
    left: -333px;
    height: calc(100vh - 60px);
    z-index: 1;
    display: block;
    position: absolute;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    overflow: auto;
  }

  ul {
    :first-child {
      ul.sidebar-list {
        max-height: inherit;
      }
    }
    li {
      padding: 10px;

      ul.sidebar-list {
        max-height: 0;
        padding-left: 23px;
        overflow: hidden;
        li {
          padding: 0 0 10px 10px;
          margin-left: -15px;

          button {
            font-size: 15px;
            background: transparent !important;
            box-shadow: none !important;
            border: 0;
            color: $link-color-sidebar;
            padding: 5px 0;
            text-align: left;
            letter-spacing: normal;
            margin-left: 15px;
            height: 32px;

            &:hover,
            &.btn-active,
            &:active {
              border: 0;
              color: $link-color-sidebar-active;
              background: transparent;
              box-shadow: none;
            }
          }
        }
      }

      button {
        .circle-back-icon {
          width: 36px;
          height: 36px;
          background: $icon-inactive;
          border-radius: 20px;
          display: flex;
          margin-right: 10px;
          align-items: center;
          justify-content: center;

          .sidebar-icon {
            width: 20px;
            height: 20px;
          }
        }

        .label-item {
          display: inline-block;
          text-align: left;
          color: #313132;
          position: relative;
          left: 45px;
          top: -25px;
          font-size: 14px;
        }

        &:hover,
        &.active {
          .circle-back-icon {
            background: $icon-active;
            -webkit-transition: background-color 0.15s linear;
            -ms-transition: background-color 0.15s linear;
            transition: background-color 0.15s linear;
            & + .label {
              color: $link-color-sidebar-active;
            }
          }
        }
      }
    }
  }
}

body.menu-active {
  @media (max-width: 768px) {
    .sidebar {
      left: 0;
    }
    .layout-container .content-with-sidebar {
      overflow: hidden;
      -webkit-transition: all 0.25s ease;
      transition: all 0.25s ease;
      filter: brightness(50%);
      pointer-events: none;
    }
  }
}

.menu-header-mobile {
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  padding-bottom: 1em;
  margin-bottom: 1em;
  position: relative;
  &:after {
    content: '';
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  ul {
    margin: 0;
    li {
      cursor: pointer;
      &:hover,
      &.active {
        color: $button-hover;
      }
    }
  }
}
