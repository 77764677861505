.example-panel {
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px;
  margin-top: -1.5rem;
  max-width: 1000px !important;
  .panel-title {
    font-size: 15.5px;
    font-weight: 900;
    color: #2e3074;
  }
}

tr {
  text-align: center;
  td {
    font-size: 14px;
  }
}

.subtitle {
  font-size: 18px;
  font-weight: 600;
}

.tips-panel {
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
  font-size: 16px;
  color: #313132;
  padding: 10px 20px;
  border-radius: 10px;
  .tip-icon {
    margin-right: 10px;
    margin-left: -5px;
  }
}

.tooltip-container {
  top: 30px;
  .tooltip-text {
    font-size: 13px;
    margin: 0;
    text-align: center;
    line-height: 15px;
    margin-bottom: 10px;
  }
}

.tooltip-arrow {
  position: absolute;
  top: 20px;
  right: 140px;
}
