.business-activity-icons {
  width: 56px;
  height: 53px;
  border-radius: 50%;
  padding: 3px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
}

.my-custom-popover .popover {
  background-color: #f7f7f7;
  padding: 30px;
  max-width: 472px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.5);
  text-align: center;

  .popover-inner {
    color: #656577;
  }
}

.popover-note {
  font-size: 14.5px;
}
.heading-color {
  color: #2e3074;
}
